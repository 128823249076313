import { useMutation, useQuery } from 'react-query';
import { supabase } from '../../../utils/supabaseConfig';


export const useDeleteUser = () => {
  const mutation = useMutation(['user'], (id) => {
    return supabase.auth.admin.deleteUser(id);
  });

  return mutation;
};