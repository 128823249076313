import React, { useState } from 'react';

import ViewDetail from './ViewDetail';
import DeleteRequest from './DeleteRequest';
import RequestStatus from './RequestStatus';
import { supabase } from '../../../utils/supabaseConfig';

import { toast } from 'react-toastify';
import {
  FunctionsFetchError,
  FunctionsHttpError,
  FunctionsRelayError,
} from '@supabase/supabase-js';

const ManageRequest = ({ request }) => {
  const [openView, setOpenView] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  const openDeleteModal = () => {
    setOpenView(false);
    setOpenDelete(true);
  };

  const [status, setStatus] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const approveRequest = async () => {
    setLoading(true);
    const payload = {
      id: request?.id,
      status: 'approved',
    };

    const { data, error } = await supabase.functions.invoke(
      'update-cancellation',
      {
        body: JSON.stringify(payload),
      },
    );

    if (error) {
      if (error instanceof FunctionsHttpError) {
        const errorMessage = await error.context.json();
        toast.error(errorMessage?.message);
        setLoading(false);
      } else if (error instanceof FunctionsRelayError) {
        toast.error(error.message);
        setLoading(false);
      } else if (error instanceof FunctionsFetchError) {
        toast.error(error.message);
        setLoading(false);
      }
    } else {
      setStatus('approved');
      setOpenView(false);
      setOpenStatus(true);
      setLoading(false);
    }
  };

  const rejectRequest = async (data) => {
    setDeleteLoading(true);

    const payload = {
      id: request?.id,
      status: 'rejected',
      note: data.reason,
    };

    const { data: result, error } = await supabase.functions.invoke(
      'update-cancellation',
      {
        body: JSON.stringify(payload),
      },
    );

    if (error) {
      if (error instanceof FunctionsHttpError) {
        const errorMessage = await error.context.json();
        toast.error(errorMessage?.message);
        setDeleteLoading(false);
      } else if (error instanceof FunctionsRelayError) {
        toast.error(error.message);
        setDeleteLoading(false);
      } else if (error instanceof FunctionsFetchError) {
        toast.error(error.message);
        setDeleteLoading(false);
      }
    } else {
      setStatus('rejected');
      setOpenDelete(false);
      setDeleteLoading(false);
      setOpenStatus(true);
    }
  };

  return (
    <>
      <button
        onClick={() => setOpenView(true)}
        className="text-primary-blue underline hover:no-underline"
      >
        View Request
      </button>

      {/* overlay */}
      <div
        className={
          openView || openDelete || openStatus
            ? 'fixed h-full  w-full bg-renaissance-black/20 top-0 left-0 z-30 flex items-center justify-center'
            : 'hidden'
        }
      >
        {openStatus && (
          <RequestStatus
            request={request}
            status={status}
            closeStatus={() => setOpenStatus(false)}
          />
        )}

        {openView && (
          <ViewDetail
            closeView={() => setOpenView(false)}
            openDeleteModal={openDeleteModal}
            request={request}
            approveRequest={approveRequest}
            isLoading={isLoading}
          />
        )}

        {openDelete && (
          <DeleteRequest
            deleteLoading={deleteLoading}
            closeDelete={() => setOpenDelete(false)}
            rejectRequest={rejectRequest}
          />
        )}
      </div>
    </>
  );
};

export default ManageRequest;
