import React, { useState, useEffect } from 'react';
import { Popup } from '../../elements';
import { MoreBlackIcon } from '../../../assets/icons';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { SelectField } from '@deposits/ui-kit-react';
import { supabase } from '../../../utils/supabaseConfig';

import { useDeleteAdmin } from '../../../helpers/hooks/queries/useAdmin';

const UserDropdown = ({ user, close, lastUser }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [role, setRole] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const option = [
    {
      text: 'Admin',
      value: 'admin',
    },
  ];

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { mutate: mutateDeleteAdmin } = useDeleteAdmin();

  useEffect(() => {
    reset({
      firstName:
        user?.user_metadata?.firstName || user?.user_metadata?.firstname,
      lastName: user?.user_metadata?.lastName || user?.user_metadata?.lastname,
      email: user?.email,
      role: user?.user_metadata?.role,
      phone: user?.user_metadata?.phone,
    });
  }, [reset, user]);

  //delete team member
  const handleDeleteAdmin = () => {
    if (user?.user_metadata?.role === 'super-admin') {
      toast.error('Cannot delete a super admin!!');
      return;
    }

    setDeleting(true);
    mutateDeleteAdmin(user?.id, {
      onSuccess: (data) => {
        queryClient.invalidateQueries('admin');
        setDeleting(false);
        toast.success('Admin deleted successfully!!');
      },
      onError: (err) => {
        setDeleting(false);
        toast.error('Something went wrong! Please try again');
        console.log({ err });
      },
    });
  };

  // Edit team member
  const handleEditAdmin = handleSubmit(async (data) => {
    setIsEditing(true);

    const res = await supabase.auth.admin.updateUserById(user?.id, {
      email: data?.email,
      user_metadata: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        phone: data?.phone,
        role: role || user?.user_metadata?.role,
      },
    });

    if (res?.data?.user !== null && res?.error === null) {
      toast.success('Team member edited successfully!!.');
      queryClient.invalidateQueries('admin');
      setIsEditing(false);
      close();
    } else {
      setIsEditing(false);
      toast.error('Something went wrong. Please try again.');
    }
  });

  return (
    <div
      className={`z-50 absolute ${
        lastUser?.id === user?.id ? 'bottom-2' : 'top-6'
      }  right-4  bg-white border border-dark-6 w-52 shadow-3xl`}
    >
      <div
        onClick={() => setEditOpen(true)}
        className="hover:bg-gray-6 cursor-pointer p-3 font-medium text-sm text-renaissance-black"
      >
        Edit user
      </div>

      <div
        onClick={() => setOpenDelete(true)}
        className="cursor-pointer hover:bg-gray-6 p-3 font-medium text-sm text-renaissance-black"
      >
        Delete user
      </div>

      {editOpen && (
        <Popup
          onClose={() => {
            setEditOpen(false);
            close();
          }}
          heading="Edit Team Member"
          body={
            <form
              onSubmit={handleEditAdmin}
              className="pb-10 !px-2 md:!px-4 grid grid-cols-1 lg:grid-cols-2 gap-8"
            >
              <div className="col-span-2 lg:col-span-1">
                <label className="block capitalize text-xs mb-1">
                  first name
                </label>

                <input
                  className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
                  type="text"
                  placeholder="Enter first name"
                  name="firstName"
                  {...register('firstName', { required: true })}
                />
                {errors.firstName && (
                  <div className="text-red-400 text-xs mt-1">
                    First name cannot be empty
                  </div>
                )}
              </div>

              <div>
                <label className="block capitalize text-xs mb-1">
                  last name
                </label>

                <input
                  className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
                  type="text"
                  placeholder="Enter last name"
                  name="firstName"
                  {...register('lastName', { required: true })}
                />
                {errors.lastName && (
                  <div className="text-red-400 text-xs mt-1">
                    Last name cannot be empty
                  </div>
                )}
              </div>

              <div className="col-span-2">
                <label className="block capitalize text-xs mb-1">
                  phone number
                </label>

                <input
                  className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
                  type="tel"
                  placeholder="Enter phone number"
                  name="phone"
                  {...register('phone', {
                    pattern: /^[0-9]*$/,
                  })}
                />
                {errors.phone && (
                  <div className="text-red-400 text-xs mt-1">
                    Phone must be valid
                  </div>
                )}
              </div>

              <div className="col-span-2">
                <label className="block capitalize text-xs mb-1">role</label>

                <SelectField
                  options={option}
                  label=""
                  placeholder={user?.user_metadata?.role}
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                />
              </div>

              <div className="col-span-2">
                <button className="text-center w-full bg-primary-green border border-gray-2 capitalize text-white text-sm font-semibold py-3 rounded-md">
                  {isEditing ? 'Saving...' : 'save changes'}
                </button>
              </div>
            </form>
          }
          backBtn={true}
        />
      )}

      {openDelete && (
        <Popup
          onClose={() => {
            setOpenDelete(false);
            close();
          }}
          heading=" Confirm Delete Team Member"
          body={
            <div className="text-center pb-10">
              <p className="text-sm text-[#74787C] whitespace-normal !px-6 ">
                You are about to remove{' '}
                <span className="text-renaissance-black font-semibold !mx-1">
                  {user?.user_metadata?.firstname}{' '}
                  {user?.user_metadata?.lastname}
                </span>
                from Le Pole Admin. Are you sure about this?
              </p>

              <div className="mt-6 flex items-center gap-x-4 justify-center">
                <button
                  onClick={() => setOpenDelete(false)}
                  className="text-center bg-white border border-gray-2 capitalize text-renaissance-black text-sm font-semibold w-36 py-3 rounded-md"
                >
                  cancel
                </button>

                <button
                  onClick={handleDeleteAdmin}
                  className="text-center bg-red-1 border border-gray-2 capitalize text-white text-sm font-semibold w-36 py-3 rounded-md"
                >
                  {isDeleting ? 'removing...' : 'remove'}
                </button>
              </div>
            </div>
          }
          backBtn={true}
        />
      )}
    </div>
  );
};

const ManageUser = ({ user, lastUser }) => {
  const [manageUser, setManageUser] = useState(false);
  const [cookies] = useCookies(['lepole_admin']);
  const adminType = cookies?.lepole_admin?.role;

  return (
    <>
      <div className="h-6 w-6">
        <button
          disabled={adminType !== 'super-admin'}
          className="relative block w-full"
          onClick={() => setManageUser(true)}
        >
          <img src={MoreBlackIcon} alt="more" />
        </button>
      </div>

      {manageUser && (
        <UserDropdown
          close={() => setManageUser(false)}
          id={user?.id}
          user={user}
          lastUser={lastUser}
        />
      )}

      {/* overlay */}
      <div
        className={
          manageUser
            ? 'fixed block h-full w-full bg-transparent top-0 left-0 z-30'
            : 'hidden'
        }
        onClick={() => setManageUser(false)}
      ></div>
    </>
  );
};

export default ManageUser;
