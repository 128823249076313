import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../router/routes';

import { ManageUser, Summary } from '../../../components/sections';
import {
  AddWhiteIcon,
  DownArrowIcon,
  FilterBlackIcon,
  SearchGrayIcon,
} from '../../../assets/icons';

import moment from 'moment';
import Tables from '../../../components/Tables';
import { createColumnHelper } from '@tanstack/react-table';

import { allSessions, registeredUser } from '../../../utils/dummyData';

import {
  CalendarBlueIcon,
  CalenderTickIcon,
  ProfilesOrangeIcon,
  userIcon,
  rightArrowIcon,
  transactionIcon,
} from '../../../assets/icons';
import { GoBack } from '../../../components/elements';
import ModalContainer from '../../../components/layouts/ModalContainer';
import { SelectPaymentOption } from '../../../components/Modals';
import {
  useAllActiveSessions,
  useAllSessions,
  useAllUpcomingSessions,
  useTotalActiveSessions,
} from '../../../helpers/hooks/queries/useSessions';
import { useCustomers } from '../../../helpers/hooks/queries/useCustomers';
import Loader from '../../../components/Loader';
import { filterData } from '../customers/Users';

const SessionOverview = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const { data, isLoading } = useAllSessions();
  const { data: dataTotalActive } = useTotalActiveSessions();
  const { data: dataAllUpcoming } = useAllUpcomingSessions();
  const { data: allUsers } = useCustomers();

  const dataUsers = useMemo(
    () => filterData(allUsers),
    [allUsers?.data?.users],
  );

  const lastUser = registeredUser[registeredUser?.length - 1];

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => 'user name', {
      id: 'Name of user',
      cell: (info) => {
        const value = info.row.original;
        const name = value?.username;
        const userId = value?.user_id;

        return (
          <Link to={`/dashboard/customers/${userId}/profile`}>
            <div className="text-primary-blue font-normal text-sm cursor-pointer hover:underline ">
              <span className=""> {name} </span>
            </div>
          </Link>
        );
      },
    }),

    columnHelper.accessor((row) => 'Session', {
      id: 'Session',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div className="text-priBlack  text-sm capitalize">
            <span> {value.type} </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => 'Date', {
      id: 'Date ',
      cell: (info) => {
        const { date, time } = info.row.original;

        return (
          <div className="text-sm">
            <span className=" text-sm text-priBlack">
              {moment.utc(date).format('Do MMMM, YYYY')}
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => row.role, {
      id: 'Hours',
      cell: (info) => (
        <span className="text-priBlack text-sm ">
          {info.row.original.duration}
        </span>
      ),
    }),

    columnHelper.accessor((row) => row.role, {
      id: 'Start Time',
      cell: (info) => (
        <span className="text-priBlack text-sm">
          {moment.utc(info.row.original.startTime).format('h:mm A')}
        </span>
      ),
    }),

    columnHelper.accessor((row) => row.role, {
      id: 'End Time',
      cell: (info) => (
        <span className="text-priBlac text-sm">
          {moment.utc(info.row.original.endTime).format('h:mm A')}
        </span>
      ),
    }),

    columnHelper.accessor((row) => 'status', {
      id: 'Cancelled',
      cell: (info) => {
        const { isCancelled } = info.row.original;

        return (
          <p
            className={`px-2 py-1 text-xs font-normal w-max items-center gap-2`}
          >
            <span />
            {isCancelled ? 'Yes' : 'No'}
          </p>
        );
      },
    }),

    // columnHelper.accessor(() => 'actions', {
    //   id: 'Actions',
    //   cell: (info) => {
    //     const { row } = info;
    //     const applicant = row?.original;

    //     return (
    //       <button>
    //         <p className='underline hover:no-underline'>Reschedule</p>
    //       </button>
    //     );
    //   },
    // }),
  ];

  return (
    <>
      {modalOpen && (
        <ModalContainer modalOpen={modalOpen} toggleModal={toggleModal}>
          <SelectPaymentOption
            toggleModal={toggleModal}
            usersData={dataUsers}
          />
        </ModalContainer>
      )}

      <div className="p-6 lg:px-6 xl:px-12 lg:py-10">
        <GoBack />

        <section className="mt-8 md:flex items-center justify-between">
          <div className="mb-2 md:mb-0">
            <h2 className="font-semibold text-base text-renaissance-black mb-1">
              Session Summary
            </h2>
            <p className="text-sm font-normal text-renaissance-black">
              Total overview of Le Pole
            </p>
          </div>

          <button
            onClick={toggleModal}
            className="flex items-center bg-renaissance-black text-white text-sm py-3 !px-5 font-semibold  rounded-md"
          >
            <img src={AddWhiteIcon} alt="add user icon" />
            <span className="ml-2">Create A Session</span>
          </button>
        </section>

        <section>
          <article className="mt-4 grid grid-cols-1 lg:grid-cols-3 gap-8">
            <Summary
              title="Total Sessions Booked"
              figures={data?.data?.length ?? '...'}
              icon={CalenderTickIcon}
              bgColor="bg-green-light"
            />
            <Summary
              title="Current Active Sessions"
              figures={dataTotalActive?.data ?? '...'}
              icon={ProfilesOrangeIcon}
              bgColor="bg-orange-light"
            />
            <Summary
              title="Total Upcoming Sessions"
              figures={dataAllUpcoming?.data?.length ?? '...'}
              icon={CalendarBlueIcon}
              bgColor="bg-blue-light"
            />
          </article>
        </section>

        <div className="mb-2 md:mb-0 mt-11">
          <h2 className="font-semibold text-base text-renaissance-black mb-1">
            Session History
          </h2>
          <p className="text-sm font-normal text-renaissance-black">
            This is an overview of all sessions
          </p>
        </div>

        <section className="mt-6 mb-4 justify-between border border-gray-6 rounded bg-white py-2 !px-2 md:!px-6 flex items-center gap-x-2 md:gap-x-6 lg:gap-x-8">
          <h3 className="text-xs md:text-sm font-medium text-gray-1 whitespace-nowrap justify-">
            ALL SESSIONS
          </h3>

          <div className="flex gap-x-4 items-center">
            {/* <div className="relative">
              <button className="flex items-center justify-between border border-gray-4 rounded py-1 !px-2 font-medium capitalize  text-renaissance-black">
                <img src={FilterBlackIcon} alt="filter" />
                <span className="text-sm block !mx-2">Sort by</span>
                <img src={DownArrowIcon} alt="filter" />
              </button>
            </div> */}

            <Link to={`/dashboard/${routes.session}/${routes.allSessions}`}>
              <button className="flex items-center justify-between border border-gray-4 rounded py-1 !px-2 md:py-2 md:!px-4 font-medium capitalize text-sm text-renaissance-black">
                <span>See all Sessions</span>
                <img className="ml-3" src={rightArrowIcon} alt="go to page" />
              </button>
            </Link>
          </div>
        </section>

        <section>
          {data?.data?.length === 0 ? (
            <div className="p-6 rounded-lg bg-gray-100">
              <p className="my-20 text-gray-500 text-2xl text-center font-medium">
                No Record Found
              </p>
            </div>
          ) : isLoading ? (
            <Loader loadingText="Loading Admin data" className="h-60 w-full" />
          ) : (
            <Tables columns={columns} data={data?.data?.slice(0, 5) || []} />
          )}
        </section>
      </div>
    </>
  );
};

export default SessionOverview;
