import React, { useEffect, useState } from 'react'
import { AddWhiteIcon, BackArrow, DownArrowIcon, FilterBlackIcon, SearchGrayIcon } from '../assets/icons';


const MultiDropdownSort = ({ options, handleSortOptions }) => {
  const [showOptions, setShowOptions] = useState(false)
  const [selected, setSelected] = useState(null)
  const [sortedValue, setSortedValue] = useState(null)

  const selectSort = (option, asc = false) => {
    handleSortOptions(option?.value, asc)
    setSortedValue(option?.name)
    setSelected(null)
    setShowOptions(false)
  }

  showOptions && document.addEventListener('click', () => {
    setSelected(null)
    setShowOptions(false)
  })

  return (

    <>
      <div onClick={e => e.stopPropagation()} className="relative " onMouseLeave={() => setSelected(null)}>
        <button onClick={() => setShowOptions(prev => !prev)} className="flex items-center justify-between border border-gray-4 rounded py-1 !px-2 font-medium capitalize  text-renaissance-black">
          <img src={FilterBlackIcon} alt="filter" />
          <span className="text-sm block !mx-2">{sortedValue || 'Sort by'}</span>
          <img src={DownArrowIcon} alt="filter" />
        </button>

        {showOptions && <div className='block absolute rounded top-10 right-0 bg-white z-10 border border-b-0 min-w-full w-max'>
          {options.map((option, index) => (
            <div className='relative'>
              <div
                role='button'
                key={option.value}
                onMouseEnter={() => setSelected(index)}
                // onClick={() => setSelected(prev => ((prev && prev === index) ? null : index))}
                className='w-full py-2 !pl-2 !pr-4 border-b overflow-hidden break-all flex hover:bg-renaissance-dark-black cursor-pointer'
              >
                <img src={BackArrow} alt="go back" className={selected === index ? '' : 'opacity-0'} />
                <p className=' pl-2'>{option.name}</p>
              </div>

              {selected === index && (
                <div
                  className='absolute block rounded bg-white right-full top-0  z-20 border text-ellipsis w-32'
                >
                  <button
                    onClick={() => selectSort(option, true)}
                    className='w-full border-b py-1.5 !px-2 transition hover:bg-renaissance-dark-black cursor-pointer' > Ascending </button>
                  <button
                    onClick={() => selectSort(option, false)}
                    className='w-full py-1.5 !px-2 transition hover:bg-renaissance-dark-black cursor-pointer'
                  >
                    Descending
                  </button>
                </div>
              )}
            </div>

          ))}
        </div>}



      </div>

    </>
  )
}

export default MultiDropdownSort