import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackArrow } from '../../assets/icons';

const GoBack = () => {
  const navigate = useNavigate();

  return (
    <div className="cursor-pointer" onClick={() => navigate(-1)}>
      <img src={BackArrow} alt="go back" />
    </div>
  );
};
export default GoBack;
