import { formatTime } from "../../../screens/dashboard/session/BookNew";

const ActiveSession = ({ item, total }) => {
  return (
    <div className="h-[140px] flex-1 flex flex-col justify-between py-5 !px-4  bg-white border border-gray-6 rounded-lg">
      <div>
        <div className="flex items-center justify-between">
          <div className="capitalize text-sm text-renaissance-black font-medium">
            {item.name}
          </div>
          <div className="text-[10px] !px-2 py-0.5 rounded-xl bg-green-light text-[#158957]">
            Active
          </div>
        </div>
        <span className="capitalize text-xs text-gray-1 mt-2 block">
          {formatTime(item?.startTime)}  - {formatTime(item?.endTime)}
        </span>
      </div>

      {typeof total === 'number' ? (
        <span className="capitalize text-xs text-gray-1 block">
          {total} active sessions
        </span>
      ) : '...'}
    </div>
  );
};

export default ActiveSession;
