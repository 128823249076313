import { useQuery } from 'react-query';
import { supabase } from '../../../utils/supabaseConfig';

export const usePaginatedTransactions = (limit, skip, currentPage, sortBy = 'created_at', ascending = false) => {
  const query = useQuery(['paginated-transactions', currentPage, sortBy, ascending], () => {
    return supabase
      .from('transactions')
      .select('*', { count: 'exact' })
      .order(sortBy, { ascending })
      .range(skip, skip + limit - 1);
  });

  return query;
};

export const useTransactions = () => {
  const query = useQuery(['transactions'], () => {
    return supabase
      .from('transactions')
      .select('*', { count: 'exact' })
      .order('created_at', { ascending: false });
  });

  return query;
};

export const usePaginatedUserTransactions = (id, limit, skip, currentPage) => {
  const query = useQuery(['user-transactions-paginated', id, currentPage], () => {
    return supabase
      .from("transactions")
      .select("*")
      .eq("user_id", id)
      .order('created_at', { ascending: false })
      .range(skip, skip + limit - 1);
  })
  return query
};

export const useUserTransactions = (id) => {
  const query = useQuery(['user-transactions', id], () => {
    return supabase
      .from("transactions")
      .select("*")
      .eq("user_id", id);
  })
  return query
};


export const useTotalCoinTransactions = () => {
  const query = useQuery(['total-coin-transactions'], () => {
    return supabase.rpc("get_and_count_transactions_by_status", {
      transaction_status: "coin balance"
    });
  });
  return query;
};

export const useTotalStripeTransactions = () => {
  const query = useQuery(['total-stripe-transactions'], () => {
    return supabase.rpc("get_and_count_transactions_by_status", {
      transaction_status: 'stripe'
    });
  });
  return query;
};

