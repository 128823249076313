import { Link } from 'react-router-dom';

const QuickAction = ({ title, text, ctaText, ctaLink, bgColor }) => {
  return (
    <div
      className={`${bgColor} h-[140px] border border-[#E7EAED] flex flex-col justify-between py-3 !px-4 rounded-lg`}
    >
      <div className="w-full">
        <h4 className="capitalize font-medium text-renaissance-black text-sm">
          {title}
        </h4>

        <span className="font-normal block text-xs mt-1 text-primary-gray ">
          {text}
        </span>
      </div>

      <div>
        <Link to={ctaLink}>
          <button className="w-full font-montserrat py-3 xl:py-2 bg-transparent text-sm border border-primary-green text-primary-green capitalize rounded-full font-medium">
            {ctaText}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default QuickAction;

//  ? 'bg-orange-1/10 dark:bg-[#DCB2601A] '
//         : slot.id === 2
//           ? 'bg-[#F5F9F7] dark:bg-[#151515]'
//           : 'bg-[#C7C9CC1A]'
//         }
