import { useQuery } from 'react-query';
import { supabase } from '../../../utils/supabaseConfig';

export const useGetCancelRequest = () => {
  const query = useQuery(['cancel-session'], () => {
    return supabase
      .from('cancellations')
      .select(
        `
        *,
        session:session_id(*)
      `,
      )
      .order('created_at', { ascending: false });
  });
  return query;
};
