import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { routes } from './routes';

import LoaderLayout from '../components/layouts/Loader';
import NotFound from '../screens/404.js';
import DashboardProtectedRoutes from '../components/layouts/DashboardProtectedRoutes';

import Login from '../screens/Login';
import Settings from '../screens/dashboard/settings/index.js';
import ForgotPassword from '../screens/ForgotPassword';
import ResetPassword from '../screens/ResetPassword';
import Overview from '../screens/dashboard/Overview';
import Wallet from '../screens/dashboard/Wallet';
import Notifications from '../screens/dashboard/notifications';
import Active from '../screens/dashboard/session/Active';
import Upcoming from '../screens/dashboard/session/Upcoming';
import History from '../screens/dashboard/session/History';
import BookNew from '../screens/dashboard/session/BookNew';
import Transactions from '../screens/dashboard/transactions';
import AllTransactions from '../screens/dashboard/transactions/AllTransactions';
import Users from '../screens/dashboard/customers/Users';
import AddUsers from '../screens/dashboard/customers/AddUsers';
import EditUser from '../screens/dashboard/customers/EditUser';
import ViewUser from '../screens/dashboard/customers/ViewUser';

import Team from '../screens/dashboard/settings/Team';
import Security from '../screens/dashboard/settings/Security';
import SessionOverview from '../screens/dashboard/session/SessionOverview';
import AllSessions from '../screens/dashboard/session/AllSessions';
import CancelReqest from '../screens/dashboard/cancelReqest.js';

const AppRouter = () => (
  <LoaderLayout>
    <BrowserRouter>
      <Routes>
        <Route index path={`/`} element={<Login />} />
        <Route path={routes.forgot_password} element={<ForgotPassword />} />
        <Route path={routes.reset_password} element={<ResetPassword />} />

        <Route
          path={routes.dashboard_home}
          element={<DashboardProtectedRoutes />}
        >
          <Route index path={routes.overview} element={<Overview />} />

          <Route path={routes.session}>
            <Route index element={<SessionOverview />} />
            <Route path={routes.allSessions} element={<AllSessions />} />
            <Route path={routes.active} element={<Active />} />
            <Route path={routes.upcoming} element={<Upcoming />} />
            <Route path={routes.history} element={<History />} />

            <Route path={routes.new} element={<BookNew />} />
          </Route>

          <Route path={routes.customers}>
            <Route index element={<Users />} />
            <Route path={routes.addUsers} element={<AddUsers />} />
            <Route path={routes.editUser} element={<EditUser />} />
            <Route path={routes.viewUser} element={<ViewUser />} />
          </Route>

          {/* <Route path={routes.wallet}>
            <Route index element={<Wallet />} />
          </Route> */}

          <Route path={routes.cancelRequest} element={<CancelReqest />} />

          <Route path={routes.transactions}>
            <Route index element={<Transactions />} />
            <Route
              path={routes.allTransactions}
              element={<AllTransactions />}
            />
          </Route>

          <Route path={routes.settings}>
            <Route index element={<Settings />} />
            <Route path={routes.team} element={<Team />} />
            <Route path={routes.security} element={<Security />} />
          </Route>

          <Route path={routes.notifications} element={<Notifications />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </LoaderLayout>
);

export default AppRouter;
