import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Summary,
  QuickAction,
  ActiveSession,
  UsersTable,
  TransactionTable,
} from '../../components/sections';

import {
  CoinBlueIcon,
  CalenderTickIcon,
  ProfilesOrangeIcon,
  userIcon,
  rightArrowIcon,
  transactionIcon,
} from '../../assets/icons';

import { activeSessions, plans } from '../../utils/dummyData';
import { routes } from '../../router/routes';
import { useCustomers } from '../../helpers/hooks/queries/useCustomers';
import { useAllSessions, useTotalActiveSessions, useTotalCoinsPurchased } from '../../helpers/hooks/queries/useSessions';
import { intervalCreator } from '../../components/sections/explore/DurationTimePicker';
import { filterData } from './customers/Users';


const getPresentSessionPlan = () => {
  const nowHour = new Date().getHours()
  let ans = plans[0]
  plans.forEach(plan => {
    if (nowHour >= plan.startTime && nowHour < plan.endTime) {
      ans = plan
      return
    }
  })
  return ans
}





const Overview = () => {
  const { data: allUsers, isLoading: loading } = useCustomers();
  const { data: allSessions } = useAllSessions()
  const { data: dataTotalActive } = useTotalActiveSessions()
  const { data: dataTotalPurchased } = useTotalCoinsPurchased()

  const formattedCurrency = typeof dataTotalPurchased?.data === 'number'
    ? new Intl.NumberFormat('en-uk', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
    }).format(dataTotalPurchased?.data)
    : '...'


  const dataTotalMemo = useMemo(
    () => filterData(allUsers),
    [allUsers?.data?.users]
  );


  return (
    <div className="p-6 lg:px-6 xl:px-12 lg:py-10">
      <h2 className="font-semibold text-base text-renaissance-black mb-1">
        Dashboard Summary
      </h2>
      <p className="text-sm font-normal text-renaissance-black">
        Total overview of Le Pole
      </p>

      <section>
        <article className="mt-4 grid grid-cols-1 lg:grid-cols-3 gap-8">
          <Summary
            title="Registered Users"
            figures={dataTotalMemo?.length || '...'}
            icon={ProfilesOrangeIcon}
            bgColor="bg-orange-light"
            link={`/${routes.dashboard_home}/${routes.customers}`}
          />
          <Summary
            title="Booked Sessions"
            // figures="3,500"
            figures={allSessions?.data?.length || '...'}
            icon={CalenderTickIcon}
            bgColor="bg-green-light"
            link={`/${routes.dashboard_home}/${routes.session}`}
          />
          <Summary
            title="Coin Purchased"
            figures={formattedCurrency}
            icon={CoinBlueIcon}
            bgColor="bg- blue-light"
            link={`/${routes.dashboard_home}/${routes.transactions}`}
          />
        </article>
      </section>

      <section className="my-10 grid grid-cols-1 xl:grid-cols-2 gap-y-8">
        <article className=" xl:pr-6">
          <h3 className="font-semibold uppercase text-sm text-renaissance-black mb-1">
            quick actions
          </h3>

          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-8">
            <QuickAction
              title="sessions"
              text="Book a session for a user"
              ctaText="book session"
              ctaLink="/dashboard/session"
              bgColor="bg-[#F5F9F7]"
            />

            <QuickAction
              title="transactions"
              text="You can view all transactions"
              ctaText="view all"
              ctaLink="/dashboard/transactions"
              bgColor="bg-gray-4/10"
            />

            <QuickAction
              title="team"
              text="You can manage team members"
              ctaText="manage"
              ctaLink="/dashboard/settings/team"
              bgColor="bg-orange-1/10"
            />
          </div>
        </article>

        <article className=" xl:border-l border-gray-4  xl:pl-6">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold uppercase text-sm text-renaissance-black">
              active sessions
            </h3>

            <Link to={`/dashboard/${routes.session}`} className='lg:hidden'>
              <button className="flex items-center justify-between border border-gray-6 rounded py-1 !px-2 md:py-2 md:!px-4 font-medium capitalize text-sm text-renaissance-black">
                <span>See all session</span>
                <img className="ml-3" src={rightArrowIcon} alt="go to page" />
              </button>
            </Link>
          </div>

          <div className='flex mt-4  flex-wrap justify-between items-center gap-x-4 gap-y-8'>
            <ActiveSession item={getPresentSessionPlan()} total={dataTotalActive?.data} />
            <Link to={`/dashboard/${routes.session}`} className='lg:inline hidden'>
              <button className="flex items-center justify-between border border-gray-6 rounded py-1 !px-2 md:py-2 md:!px-4 font-medium capitalize text-sm text-renaissance-black">
                <span>See all sessions</span>
                <img className="ml-3" src={rightArrowIcon} alt="go to page" />
              </button>
            </Link>
          </div>
          {/* 
              <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-8 items-center ">
            {activeSessions.map((item) => (
              ))}
              </div>
           */}

        </article>
      </section>

      <section className="grid grid-cols-1 xl:grid-cols-2 gap-8">
        <article>
          <div className="bg-white flex flex-wrap gap-y-4 items-center justify-between py-3 !px-4 md:!px-6 rounded ">
            <h3 className="flex items-center font-semibold uppercase text-sm text-renaissance-black">
              <img src={userIcon} alt="user icon" />
              <span className="ml-2">registered users</span>
            </h3>

            <Link to={`/dashboard/${routes.customers}`}>
              <button className="flex items-center justify-between border border-gray-6 rounded py-1 !px-2 md:py-2 md:!px-4 font-medium capitalize text-sm text-renaissance-black">
                <span>See all users</span>
                <img className="ml-3" src={rightArrowIcon} alt="go to page" />
              </button>
            </Link>
          </div>

          <div className="mt-2">
            <UsersTable />
          </div>
        </article>
        <article>
          <div className="bg-white flex items-center justify-between py-3 !px-6 rounded ">
            <h3 className="flex items-center font-semibold uppercase text-sm text-renaissance-black">
              <img src={transactionIcon} alt="user icon" />
              <span className="ml-2">transactions</span>
            </h3>

            <Link to={`/dashboard/${routes.transactions}`}>
              <button className="flex items-center justify-between border border-gray-6 rounded py-1 !px-2 md:py-2 md:!px-4 font-medium capitalize text-sm text-renaissance-black">
                <span>See all</span>
                <img className="ml-3" src={rightArrowIcon} alt="go to page" />
              </button>
            </Link>
          </div>

          <div className="mt-2">
            <TransactionTable />
          </div>
        </article>
      </section>
    </div>
  );
};

export default Overview;
