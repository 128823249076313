const routes = {
  login: 'login',
  forgot_password: 'forgot-password',
  reset_password: 'reset-password',
  create_password: 'create-password',
  dashboard_home: 'dashboard',
  overview: 'overview',
  session: 'session',
  allSessions: 'all',
  active: 'active',
  upcoming: 'upcoming',
  history: 'history',
  new: 'new',
  wallet: 'wallet',
  settings: 'settings',
  team: 'team',
  security: 'security',
  notifications: 'notifications',
  transactions: 'transactions',
  allTransactions: 'all',
  customers: 'customers',
  users: 'users',
  addUsers: 'add-user',
  editUser: ':id/edit',
  viewUser: ':id/profile',
  cancelRequest: 'cancel-request',
};

export { routes };
