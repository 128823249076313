import Tables from '../../Tables';
import { createColumnHelper } from '@tanstack/react-table';

import { registeredUser } from '../../../utils/dummyData';

import moment from 'moment';
import { useCustomers } from '../../../helpers/hooks/queries/useCustomers';
import Loader from '../../Loader';
import { Link } from 'react-router-dom';

const UsersTable = () => {

  const { data: customersData, isLoading } = useCustomers()


  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => 'Name of user', {
      id: 'Name of user',
      cell: (info) => {
        const value = info.row.original;
        const firstname = value?.user_metadata?.firstname;
        const lastname = value?.user_metadata?.lastname;
        const userId = value?.id;


        return (
          <Link to={`/dashboard/customers/${userId}/profile`}>
            <div className="text-primary-blue font-normal capitalize transition hover:underline cursor-pointer">
              <span className=" text-sm">{firstname}</span>
              <span className="ml-1 text-sm">{lastname}</span>
            </div>
          </Link>
        );
      },
    }),

    columnHelper.accessor((row) => 'date registered', {
      id: 'date registered',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div className="text-renaissance-black capitalize">
            <span className="block text-sm ">
              {moment(value?.created_at).format('DD MMM yyyy')}
            </span>
            <span className="text-[10px] text-gray-2">
              {moment(value?.created_at).format('hh:mm Z')}
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => 'status', {
      id: 'status',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div
            className={` capitalize ${value?.email_confirmed_at
              ? 'text-green-1 '
              : 'bg-orange-2 text-xs text-white !px-2 py-1 rounded-[10px] w-fit'
              }`}
          >
            <span className="text-sm">
              {value?.email_confirmed_at ? 'verified' : 'unverified'}
            </span>
          </div>
        );
      },
    }),
  ];

  return (
    <div>
      {customersData?.data?.users?.length === 0 ? (
        <div className="p-6 rounded-lg bg-gray-100">
          <p className="my-20 text-gray-500 text-2xl text-center font-medium">
            No Record Found
          </p>
        </div>
      ) : isLoading ? (
        <Loader loadingText="Loading Admin data" className="h-60 w-full" />
      ) : (
        <Tables columns={columns} data={
          customersData?.data?.users
            ?.filter(item => item?.user_metadata?.firstname)
            ?.slice(0, 4)
          || []
        } />)}
    </div>
  );
};

export default UsersTable;
