import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Popup } from '../../elements';
import { MoreBlackIcon, AlertDeleteIcon } from '../../../assets/icons';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useDeleteUser } from '../../../helpers/hooks/queries/useUser';

const UserDropdown = ({ user, close, lastUser }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const queryString = encodeURIComponent(JSON.stringify(user));

  const queryClient = useQueryClient();

  const { mutate: mutateDeleteUser } = useDeleteUser();

  //delete users
  const handleDeleteUser = () => {

    setDeleting(true);
    mutateDeleteUser(user?.id, {
      onSuccess: (data) => {
        queryClient.invalidateQueries('user');
        setDeleting(false);
        setOpenDelete(false);
        toast.success('User deleted successfully!!');
      },
      onError: (err) => {
        setDeleting(false);
        toast.error('Something went wrong! Please try again');
        // console.log({ err });
      },
    });
  };

  return (
    <div
      className={`z-50 absolute ${
        lastUser?.id === user?.id ? 'bottom-2' : 'top-6'
      }  right-4  bg-white border border-dark-6 w-52 shadow-3xl`}
    >
      <Link to={`/dashboard/customers/${user?.id}/edit?data=${queryString}`}>
        <div className="hover:bg-gray-6 cursor-pointer p-3 font-medium text-sm text-renaissance-black" user={user}>
          Edit user
        </div>
      </Link>

      <div
        onClick={() => setOpenDelete(true)}
        className="cursor-pointer hover:bg-gray-6 p-3 font-medium text-sm text-renaissance-black"
      >
        Delete user
      </div>

      {openDelete && (
        <Popup
          onClose={() => {
            setOpenDelete(false);
            close();
          }}
          heading="Delete"
          body={
            <div className="text-center pb-10">
              <img
                className="mx-auto"
                src={AlertDeleteIcon}
                alt="delete warning"
              />

              <h6 className="mt-6 mb-2 text-black text-2xl font-medium">
                Confirm Delete
              </h6>

              <p className="text-sm text-[#74787C]">
                Confirm you want to this user
              </p>

              <div className="mt-6 flex items-center gap-x-4 justify-center">
                <button
                  onClick={() => setOpenDelete(false)}
                  className="text-center bg-white border border-gray-2 capitalize text-renaissance-black text-sm font-semibold w-36 py-3 rounded-md"
                >
                  cancel
                </button>

                <button 
                onClick={handleDeleteUser}
                className="text-center bg-red-1 border border-gray-2 capitalize text-white text-sm font-semibold w-36 py-3 rounded-md">
                  {isDeleting ? 'Deleting...' : 'confirm delete'}
                </button>
              </div>
            </div>
          }
          backBtn={true}
        />
      )}
    </div>
  );
};

const ManageUser = ({ user, lastUser }) => {
  const [manageUser, setManageUser] = useState(false);
  return (
    <>
      <div className="h-6 w-6">
        <button
          className="relative block w-full"
          onClick={() => setManageUser(true)}
        >
          <img src={MoreBlackIcon} alt="more" />
        </button>
      </div>

      {manageUser && (
        <UserDropdown
          close={() => setManageUser(false)}
          id={user?.id}
          user={user}
          lastUser={lastUser}
        />
      )}

      {/* overlay */}
      <div
        className={
          manageUser
            ? 'fixed block h-full w-full bg-transparent top-0 left-0 z-30'
            : 'hidden'
        }
        onClick={() => setManageUser(false)}
      ></div>
    </>
  );
};

export default ManageUser;
