import React, { useState, useMemo } from 'react';
import { GoBack } from '../../../components/elements';
import {
  SettingsTab,
  ManageMember,
  AddTeam,
} from '../../../components/sections';
import Tables from '../../../components/Tables';
import Loader from '../../../components/Loader';
import { AddWhiteIcon } from '../../../assets/icons';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { useCookies } from 'react-cookie';

import { useAdminUsers } from '../../../helpers/hooks/queries/useAdmin';

const Team = () => {
  const [cookies] = useCookies(['lepole_admin']);
  const role = cookies?.lepole_admin?.role;

  const [openAdd, setOpenAdd] = useState(false);

  const { data: adminUsers, isLoading: loading } = useAdminUsers();

  const allAdmin =
    !loading &&
    adminUsers?.data?.users?.filter(
      (user) =>
        user?.user_metadata?.role === 'admin' ||
        user?.user_metadata?.role === 'super-admin',
    );

  const lastUser = allAdmin[allAdmin.length - 1];

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => 'team member', {
      id: 'team member',
      cell: (info) => {
        const value = info.row.original;
        const firstname =
          value?.user_metadata?.firstName || value?.user_metadata?.firstname;
        const lastname =
          value?.user_metadata?.lastName || value?.user_metadata?.lastname;

        return (
          <div className="text-renaissance-black font-normal capitalize">
            <span className="text-sm ">{firstname}</span>
            <span className="ml-1">{lastname}</span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => row.email, {
      id: 'email',
      cell: (info) => {
        const value = info.row.original;
        const email = value?.email;

        return (
          <span className="text-primary-blue text-sm normal-case">
            {email?.length > 20 ? `${email.substring(0, 15)}...` : email}
          </span>
        );
      },
    }),

    columnHelper.accessor((row) => 'role', {
      id: 'role',
      cell: (info) => {
        const value = info.row.original;
        return (
          <div className="bg-badge-gray text-[10px] w-fit text-center py-1 !px-2 rounded-xl">
            {value?.user_metadata?.role}
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => 'last login', {
      id: 'last login',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div className="text-renaissance-black capitalize">
            <span className="block text-sm ">
              {moment(value?.last_sign_in_at).format('DD MMM yyyy')}
            </span>
            <span className="text-[10px] text-gray-2">
              {moment(value?.last_sign_in_at).format('hh:mm Z')}
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor(() => 'action', {
      id: 'action',

      cell: (info) => {
        const { row } = info;
        const user = row?.original;

        return (
          <div>
            <ManageMember user={user} lastUser={lastUser} />
          </div>
        );
      },
    }),
  ];

  const dataTable = useMemo(() => {
    return allAdmin;
  }, [allAdmin]);

  return (
    <div className="p-6 lg:p-6 xl:px-12">
      <SettingsTab />

      <section className="mt-8">
        <GoBack />
        <section className="mt-8 md:flex items-center justify-between">
          <div className="mb-2 md:mb-0">
            <h2 className="font-semibold text-base text-renaissance-black mb-1">
              Team Members
            </h2>
            <p className="text-sm font-normal text-renaissance-black">
              You can add team members and assign roles to them as well
            </p>
          </div>

          <button
            disabled={role !== 'super-admin'}
            onClick={() => setOpenAdd(true)}
            className="flex items-center bg-renaissance-black capitalize text-white text-sm py-3 !px-5 font-semibold  rounded-md"
          >
            <img src={AddWhiteIcon} alt="add user icon" />
            <span className="ml-2">add team member</span>
          </button>
        </section>

        <section className="  mt-6 mb-4">
          <article className="lg:flex items-center justify-between lg:gap-x-8 py-2 !px-2 md:!px-6  border border-gray-6 rounded">
            <div className="md:flex items-center md:gap-8">
              <h3 className="uppercase text-xs md:text-sm font-medium text-gray-1 whitespace-nowrap">
                all team members
              </h3>
            </div>
          </article>
        </section>

        <section>
          {adminUsers?.data?.length < 1 ? (
            <div className="p-6 rounded-lg bg-gray-100">
              <p className="mt-20 text-gray-500 text-2xl text-center font-medium">
                No Record Found
              </p>
            </div>
          ) : loading ? (
            <Loader loadingText="Loading Admin data" className="h-96 w-full" />
          ) : (
            <Tables
              columns={columns}
              data={dataTable}
              loading={loading}
              // totalPage={totalPage}
              // currentPage={currentPage}
              // setCurrentPage={setCurrentPage}
              // isPaginated={true}
            />
          )}
        </section>
      </section>

      {openAdd && <AddTeam onClose={() => setOpenAdd(false)} />}
    </div>
  );
};

export default Team;
