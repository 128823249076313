import React from 'react';

import Loader from '../../Loader';
import Tables from '../../Tables';
import ManageRequest from './ManageRequest';
import { createColumnHelper } from '@tanstack/react-table';

import moment from 'moment';

const CancelTable = ({ dataTable, isLoading }) => {
  // const lastUser = cancelData[cancelData?.length - 1];
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => 'Session', {
      id: 'Session Type',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div className="text-priBlack  text-sm capitalize">
            <span> {value.session?.type} </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => 'user', {
      id: 'User',
      cell: (info) => {
        const value = info.row.original;
        const name = value?.session?.username;

        return (
          <div className="text-primary-blue font-normal capitalize hover:underline cursor-pointer">
            <span className=""> {name} </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => 'Date', {
      id: 'Date ',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div className="text-sm">
            <span className=" text-sm text-priBlack">
              {moment.utc(value?.session?.date).format('Do MMMM, YYYY')}
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => row.role, {
      id: 'Hours',
      cell: (info) => (
        <span className="text-priBlack text-sm ">
          {info.row.original.session?.duration}
        </span>
      ),
    }),

    columnHelper.accessor((row) => row.role, {
      id: 'Start Time',
      cell: (info) => (
        <span className="text-priBlack text-sm">
          {moment.utc(info.row.original.session?.startTime).format('h:mm A')}
        </span>
      ),
    }),

    columnHelper.accessor((row) => row.role, {
      id: 'End Time',
      cell: (info) => (
        <span className="text-priBlac text-sm">
          {moment.utc(info.row.original.session?.endTime).format('h:mm A')}
        </span>
      ),
    }),

    columnHelper.accessor((row) => 'status', {
      id: 'Status',
      cell: (info) => {
        const { status } = info.row.original;
        let statusStyle;

        switch (status) {
          case 'rejected':
            statusStyle = ' text-red-700 bg-red-500/20 ';
            break;
          case 'approved':
            statusStyle = 'text-green-700 bg-green-500/20';
            break;
          default:
            statusStyle = ' text-yellow-700 bg-yellow-500/20  ';
            break;
        }

        return (
          <div
            className={`px-2 py-1 text-xs font-normal w-max items-center gap-2 ${statusStyle} rounded-full `}
          >
            <span />
            {status}
          </div>
        );
      },
    }),

    columnHelper.accessor(() => 'actions', {
      id: 'Actions',
      cell: (info) => {
        const { row } = info;
        const request = row?.original;

        return (
          <>
            <ManageRequest request={request} />
          </>
        );
      },
    }),
  ];

  return (
    <section>
      {dataTable?.length < 1 ? (
        <div className="p-6 rounded-lg bg-gray-100">
          <p className="my-20 text-gray-500 text-2xl text-center font-medium">
            No Record Found
          </p>
        </div>
      ) : isLoading ? (
        <Loader loadingText="Loading Cancel request" className="h-96 w-full" />
      ) : (
        <Tables
          //   isPaginated
          columns={columns}
          data={dataTable}
          loading={isLoading}
          // totalPage={totalPage}
          //   currentPage={searchValue ? currentSearchedPage : currentPage}
          //   setCurrentPage={searchValue ? setCurrentSearchedPage : setCurrentPage}
        />
      )}
    </section>
  );
};

export default CancelTable;
