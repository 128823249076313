import React, { useState } from 'react';

import { ReactComponent as CaretRightIcon } from '../../assets/icons/caret-right.svg';
import { ReactComponent as CaretLeftIcon } from '../../assets/icons/caret-left.svg';

const numbers = (data) =>
  Array(data)
    .fill(0)
    .map((n, i) => i + 1);

const Pagination = ({ totalPage, setCurrentPage, currentPage }) => {
  const sliceDigit = 10;
  const [first, setFirst] = useState(0);
  const [last, setLast] = useState(sliceDigit);
  // slice(0,8)

  const slicePage = (first, last) => {
    return numbers(totalPage).slice(first, last);
  };

  return (
    <div className="flex items-center text-sm text-[#1C1C23] font-medium">
      <button
        onClick={() => {
          setFirst(first - sliceDigit);
          setLast(last - sliceDigit);
        }}
        className="w-fit h-10 flex items-center"
        disabled={first === 0}
      >
        <CaretLeftIcon stroke={currentPage === 1 ? '#8C97A7' : '#006666'} />

        <span
          className={`ml-2 text-sm ${
            currentPage === 1 ? 'text-[#8C97A7]' : 'text-primary-green'
          }`}
        >
          Previous
        </span>
      </button>

      {first > 0 && (
        <div className="w-10 h-10 flex items-center justify-center border border-dark-6">
          ...
        </div>
      )}

      {slicePage(first, last).length > 0 &&
        slicePage(first, last).map((num) => {
          return (
            <button
              className={`${
                num === currentPage ? 'bg-primary-green text-white' : ''
              } mx-2 w-10 h-10  hover:text-white hover:bg-primary-green/50 transition-all ease-in-out duration-300`}
              onClick={() => {
                setCurrentPage(num);
              }}
              key={num}
            >
              {num}
            </button>
          );
        })}

      {last < totalPage && (
        <div className="w-10 h-10 flex items-center justify-center border border-dark-6">
          ...
        </div>
      )}

      <button
        onClick={() => {
          setFirst(first + sliceDigit);
          setLast(last + sliceDigit);
        }}
        className="w-fit h-10 flex items-center rounded-r border-y border-r border-dark-6"
        disabled={last >= totalPage}
      >
        <span
          className={`text-sm mr-2 ${
            currentPage !== totalPage ? 'text-[#8C97A7]' : 'text-primary-green'
          }`}
        >
          Next
        </span>
        <CaretRightIcon
          className={
            currentPage === totalPage
              ? 'stroke-[#8C97A7]'
              : 'stroke-primary-green'
          }
        />
      </button>
    </div>
  );
};

export default Pagination;
