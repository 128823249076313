import React from 'react';
import { Button } from '@deposits/ui-kit-react';
import { useForm } from 'react-hook-form';

import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import { WarningOrange } from '../../../assets/icons';

const DeleteRequest = ({ deleteLoading, closeDelete, rejectRequest }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div className="w-full lg:w-[620px] h-full lg:h-auto overflow-y-auto rounded-tl-2xl rounded-tr-2xl bg-neutral ">
      <div className="flex px-2 lg:!px-8 py-5 justify-between bg-white">
        <h4 className="font-bold text-xl text-renaissance-black">
          Request Details
        </h4>
        <button className="inline dark:hidden" onClick={closeDelete}>
          <CloseSvg />
        </button>
      </div>
      <div className="bg-neutral  py-9 px-2 lg:!px-8">
        <section className="">
          <div className="mt-4 mb-8 bg-orange_600 pl-3 pr-1 py-1 rounded">
            <div className="bg-white py-3 !px-4 text-neutral_06 text-sm flex items-start gap-x-4">
              <img src={WarningOrange} alt="information alert" />
              <span className="flex-1 block text-xs lg:text-sm whitespace-normal lg:w-2/3">
                {' '}
                You can cancel your session within 4 hours of booking when you
                pay with coin and 8 hours when you pay as you go.
              </span>
            </div>
          </div>
        </section>
        <section className="mt-4">
          <h5 className="font-bold text-lg  text-renaissance-black ">
            Reason for cancelling
          </h5>
          <form onSubmit={handleSubmit(rejectRequest)}>
            <div className="mt-2">
              <label className="block">
                (Please state the reason for decline, this is what the customer
                sees)
              </label>
              <textarea
                rows="5"
                className="mt-4 w-full p-2 border border-gray_300 rounded focus:outline-0 focus:border-primary-green"
                placeholder="Type here"
                {...register('reason', {
                  required: true,
                })}
              ></textarea>

              {errors.reason ? (
                <div className="text-red-500">Please fill this box.</div>
              ) : null}
            </div>
            <div className="mt-6 flex flex-col md:flex-row gap-4">
              <Button
                disabled={deleteLoading}
                className="!bg-red-1 !w-full !border-0 !px-8 !text-primary-white"
                size="xlarge"
              >
                {deleteLoading ? 'Declining..' : 'Decline'}
              </Button>

              <Button
                onClick={(e) => {
                  e.preventDefault();
                  closeDelete();
                }}
                className="!bg-white !w-full !border !border-primary-green !px-8 !text-primary-green"
                size="xlarge"
              >
                Cancel
              </Button>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default DeleteRequest;
