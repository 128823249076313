import React, { useMemo } from 'react';
import { CancelSummary, CancelTable } from '../../components/sections';

import {
  CancelSquareOrange,
  CancelSquareRed,
  CancelSquareGreen,
} from '../../assets/icons';

import { useGetCancelRequest } from '../../helpers/hooks/queries/useCancel';

const CancelReqest = () => {
  const { data, isLoading } = useGetCancelRequest();

  const result = data?.data?.reduce(
    (acc, cancellation) => {
      acc[cancellation.status] = acc[cancellation.status] + 1;
      return acc;
    },
    { pending: 0, approved: 0, rejected: 0 },
  );

  const totalRequest = result?.approved + result?.pending + result?.rejected;

  const dataTable = useMemo(() => {
    return data?.data || [];
  }, [data?.data]);

  return (
    <div className="p-6 lg:px-6 xl:px-12 lg:py-10">
      <h2 className="font-semibold text-base text-renaissance-black mt-8 mb-1">
        Cancellation Summary
      </h2>
      <p className="text-sm font-normal text-renaissance-black">
        A preview of all cancellation request
      </p>

      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <CancelSummary
          heading="Total Requests"
          value={totalRequest || 0}
          icon={CancelSquareOrange}
        />

        <CancelSummary
          heading="Approved Requests"
          value={result?.approved || 0}
          icon={CancelSquareGreen}
        />

        <CancelSummary
          heading="Decline Requests"
          value={result?.rejected || 0}
          icon={CancelSquareRed}
        />
      </div>

      <div className="mt-8">
        <CancelTable dataTable={dataTable} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default CancelReqest;
