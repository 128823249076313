import React from 'react';
import { ExportGrayIcon } from '../../../assets/icons';
import { Link } from 'react-router-dom';

const Summary = ({ title, icon, bgColor, figures, link }) => {
  return (
    <div className="flex flex-col justify-between  bg-white border border-[#E7EAED] h-44 rounded-lg p-6">
      <div className="flex items-center justify-between">
        <div
          className={`${bgColor} w-9 h-9 rounded-[10px] flex items-center justify-center`}
        >
          <img src={icon} alt="icon" />
        </div>

        {link && <Link to={link} className="cursor-pointer">
          <img src={ExportGrayIcon} alt="goto page" />
        </Link>}
      </div>

      <div className="mt-4">
        <span className="text-sm text-gray-1 block mb-1">{title}</span>
        <span className="font-semibold text-renaissance-black text-lg md:text-2xl block">
          {figures}
        </span>
      </div>
    </div>
  );
};

export default Summary;
