import { routes } from '../../router/routes';

import { ReactComponent as OverviewIcon } from '../../assets/icons/explore-icon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as CustomerIcon } from '../../assets/icons/customer.svg';
import { ReactComponent as TransactionIcon } from '../../assets/icons/transaction-note.svg';
import { ReactComponent as CancelRequestIcon } from '../../assets/icons/cancel.svg';

export const sidebarItems = [
  {
    title: 'Overview',
    pageTitle: 'Dashboard',
    Icon: OverviewIcon,
    link: `/${routes.dashboard_home}/${routes.overview}`,
  },
  {
    title: 'Customers',
    pageTitle: 'Users',
    Icon: CustomerIcon,
    link: `/${routes.dashboard_home}/${routes.customers}`,
  },
  {
    title: 'Sessions',
    pageTitle: 'Sessions',
    Icon: CalendarIcon,
    link: `/${routes.dashboard_home}/${routes.session}`,
  },
  {
    title: 'Transactions',
    pageTitle: 'Transactions',
    Icon: TransactionIcon,
    link: `/${routes.dashboard_home}/${routes.transactions}`,
  },
  {
    title: 'Cancel Request',
    pageTitle: 'Cancel Request',
    Icon: CancelRequestIcon,
    link: `/${routes.dashboard_home}/${routes.cancelRequest}`,
  },
  {
    title: 'Settings',
    pageTitle: 'Settings',
    Icon: SettingsIcon,
    link: `/${routes.dashboard_home}/${routes.settings}`,
  },
];
