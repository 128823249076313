import React, { useEffect, useState } from 'react';
import { GoBack } from '../../../components/elements';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { supabase } from '../../../utils/supabaseConfig';
import { useQueryClient } from 'react-query';
import { routes } from '../../../router/routes';

const EditUser = ({user}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

const [openDelete, setOpenDelete] = useState(false);
const [isDeleting, setDeleting] = useState(false);
const [editOpen, setEditOpen] = useState(false);
const [isEditing, setIsEditing] = useState(false);
const [role, setRole] = useState('');

const navigate = useNavigate();

const queryClient = useQueryClient();

const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const user_data = queryParams.get('data');
const myObject = user_data ? JSON.parse(decodeURIComponent(user_data)) : null;

  useEffect(() => {
    reset({
      firstName: myObject?.user_metadata?.firstname,
      lastName: myObject?.user_metadata?.lastname,
      email: myObject?.email,
      role: myObject?.user_metadata?.role,
      phone: myObject?.user_metadata?.phone,
      
    });

  }, [reset, user]);

  // Edit users
  const handleEditUser = handleSubmit(async (data) => {
    setIsEditing(true);

    const res = await supabase.auth.admin.updateUserById(myObject?.id, {
      // email: data?.email,
      user_metadata: {
        firstname: data?.firstName,
        lastname: data?.lastName,
        phone: data?.phone,
        role: role || myObject?.user_metadata?.role,
      },
    });

    console.log(res);

    if (res?.data?.user !== null && res?.error === null) {
      toast.success('User details edited successfully!!.');
      queryClient.invalidateQueries('admin');
      setIsEditing(false);
      navigate(`/dashboard/${routes.customers}`)
      // close();
    } else {
      setIsEditing(false);
      toast.error('Something went wrong. Please try again.');
    }
  });

  return (

    <div className="p-6 lg:px-6 xl:px-12 lg:py-10">
      <GoBack />

      <h2 className="font-semibold text-base text-renaissance-black mt-8 mb-1">
        Edit user details
      </h2>
      <p className="text-sm font-normal text-renaissance-black">
        Modify a user data
      </p>

      <form className="mt-6 max-w-4xl grid grid-cols-1 lg:grid-cols-2 gap-8" onSubmit={handleEditUser}>
        <div>
          <label className="block capitalize text-xs mb-1">first name</label>

          <input
            className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
            type="text"
            placeholder="Enter first name"
            name="firstName"
            {...register('firstName', { required: true })}
          />
          {errors.firstName && (
            <div className="text-red-400 text-xs">
              First Name must not be empty
            </div>
          )}
        </div>

        <div>
          <label className="block capitalize text-xs mb-1">last name</label>

          <input
            className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
            type="text"
            placeholder="Enter last name"
            name="lastName"
            {...register('lastName', { required: true })}
          />
          {errors.lastName && (
            <div className="text-red-400 text-xs">
              Last Name must not be empty
            </div>
          )}
        </div>

        <div>
          <label className="block capitalize text-xs mb-1">email address</label>

          <input
            className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
            type="email"
            placeholder="Enter email address"
            name="email"
            disabled
            {...register('email', {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            })}
          />
          {errors.email && (
            <div className="text-red-400 text-xs">Email must be valid</div>
          )}
        </div>

        <div>
          <label className="block capitalize text-xs mb-1">phone number</label>

          <input
            className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
            type="tel"
            placeholder="Enter phone number"
            name="phone"
            {...register('phone', {
              required: true,
              pattern: /^[0-9]*$/,
            })}
          />
          {errors.phone && (
            <div className="text-red-400 text-xs">Phone must be valid</div>
          )}
        </div>

        <div>
          <button className="flex items-center bg-primary-green capitalize text-white text-sm py-3 font-semibold !px-5 rounded-md">
            {isEditing ? 'Updating...' : 'update user details'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditUser;
