import React, { useState } from 'react';
import { GoBack } from '../../../components/elements';
import { useForm } from 'react-hook-form';
import { supabase } from '../../../utils/supabaseConfig';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AddUsers = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const watchPassword = watch('password', '');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const addTeam = handleSubmit(async (data) => {
    setIsSubmitting(true);

    const res = await supabase.auth.signUp({
      email: data.email,
      password: data.password,
      phone: data.phone,
      confirmpassword: data.confirmpassword,
      options: {
        data: {
          firstname: data.firstName,
          lastname: data.lastName,
          phone: data.phone,
          wallet: 100,
          role: 'user',
        },
      },
    });

    if (res?.data?.user !== null && res?.error === null) {
      toast.success('Add User Successful.');
      reset();
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
    }
  });

  return (
    <div className="p-6 lg:px-6 xl:px-12 lg:py-10">
      <GoBack />

      <h2 className="font-semibold text-base text-renaissance-black mt-8 mb-1">
        Add a new user
      </h2>
      <p className="text-sm font-normal text-renaissance-black">
        Add a new user
      </p>

      <form
        onSubmit={addTeam}
        className="mt-6 max-w-4xl grid grid-cols-1 lg:grid-cols-2 gap-8"
      >
        <div>
          <label className="block capitalize text-xs mb-1">first name</label>

          <input
            className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
            type="text"
            placeholder="Enter first name"
            name="firstName"
            {...register('firstName', { required: true })}
          />
          {errors.firstName && (
            <div className="text-red-400 text-xs">
              First Name must not be empty
            </div>
          )}
        </div>

        <div>
          <label className="block capitalize text-xs mb-1">last name</label>

          <input
            className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
            type="text"
            placeholder="Enter last name"
            name="lastName"
            {...register('lastName', { required: true })}
          />
          {errors.lastName && (
            <div className="text-red-400 text-xs">
              Last Name must not be empty
            </div>
          )}
        </div>

        <div>
          <label className="block capitalize text-xs mb-1">email address</label>

          <input
            className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
            type="email"
            placeholder="Enter email address"
            name="email"
            {...register('email', {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            })}
          />
          {errors.email && (
            <div className="text-red-400 text-xs">Email must be valid</div>
          )}
        </div>

        <div>
          <label className="block capitalize text-xs mb-1">phone number</label>

          <input
            className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
            type="tel"
            placeholder="Enter phone number"
            name="phone"
            {...register('phone', {
              required: true,
              pattern: /^[0-9]*$/,
            })}
          />
          {errors.phone && (
            <div className="text-red-400 text-xs">Phone must be valid</div>
          )}
        </div>

        <div>
          <label className="block capitalize text-xs mb-1">password</label>
          <input
            name="password"
            className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
            type="password"
            placeholder="Enter password"
            {...register('password', {
              required: true,
              minLength: 8,
            })}
          />
          {errors.password && (
            <div className="text-red-400 text-xs mt-1">
              Password must have a minimum length of 8
            </div>
          )}
        </div>

        <div>
          <label className="block capitalize text-xs mb-1">
            confirm password
          </label>
          <input
            name="confirmpassword"
            className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
            type="password"
            placeholder="Confirm password"
            {...register('confirmpassword', {
              required: true,
              minLength: 8,
              validate: (value) =>
                value === watchPassword || 'The passwords do not match',
            })}
          />
          {errors.confirmpassword && (
            <div className="text-red-400 text-xs mt-1">
              Password must be a match
            </div>
          )}
        </div>

        <div>
          <button className="flex items-center bg-primary-green capitalize text-white text-sm py-3 font-semibold !px-5 rounded-md">
            {isSubmitting ? 'Adding' : 'add new user'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddUsers;
