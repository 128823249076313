import React from 'react';
import { SettingsTab } from '../../../components/sections';

const Security = () => {
  return (
    <div className="p-6 lg:p-6 xl:px-12">
      <SettingsTab />

      <div>
        Security
      </div>
    </div>
  );
};

export default Security;
