import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { routes } from '../../../router/routes';
import { GoBack, Popup } from '../../../components/elements';
import { Heading2, H3 } from '../../../components/Headings';
import Tables from '../../../components/Tables';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { transactions } from '../../../utils/dummyData';
import { useLocation } from 'react-router-dom';
import { SearchGrayIcon, AlertDeleteIcon } from '../../../assets/icons';
import { useCustomer } from '../../../helpers/hooks/queries/useCustomers';
import { usePaginatedUserTransactions, useUserTransactions } from '../../../helpers/hooks/queries/useTransactions';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useDeleteUser } from '../../../helpers/hooks/queries/useUser';
import {
  useTransactions,
  usePaginatedTransactions,
} from '../../../helpers/hooks/queries/useTransactions';
import Loader from '../../../components/Loader';

const limit = 20;


const ViewUser = () => {

  const params = useParams();
  const userId = params?.id;

  const [currentPage, setCurrentPage] = useState(1);

  const skip = (currentPage - 1) * limit;

  const { isLoading: loading, data: paginatedTransactions } = usePaginatedUserTransactions(
    userId,
    limit,
    skip,
    currentPage,
    // sortOptions.sortBy,
    // sortOptions.asc,
  );

  const [openDelete, setOpenDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const navigate = useNavigate();

  const { data } = useCustomer(userId);
  const { data: transactions } = useUserTransactions(userId);

  const queryClient = useQueryClient();

  const { mutate: mutateDeleteUser } = useDeleteUser();

  const dataTable = useMemo(() => {
    return paginatedTransactions?.data;
  }, [paginatedTransactions?.data]);

  const totalPage =
    transactions?.data && Math.ceil(transactions?.data?.length / limit);

    // console.log(transactions?.data);

  //delete users
  const handleDeleteUser = () => {

    setDeleting(true);
    mutateDeleteUser(userId, {
      onSuccess: (data) => {
        queryClient.invalidateQueries('user');
        setDeleting(false);
        setOpenDelete(false);
        toast.success('User deleted successfully!!');
        navigate(`/dashboard/${routes.customers}`)
      },
      onError: (err) => {
        setDeleting(false);
        toast.error('Something went wrong! Please try again');
        // console.log({ err });
      },
    });
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => 'user', {
      id: 'Name of user',
      cell: (info) => {
        const value = info.row.original;

        const username = value?.username;

        return (
          <div className="text-black font-normal capitalize">
            <span className="text-sm ">{username}</span>
            {/* <span className="ml-1">{lastname}</span> */}
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => row.description, {
      id: 'description',
      cell: (info) => (
        <span className="text-renaissance-black text-sm">
          {info.getValue()}
        </span>
      ),
    }),

    columnHelper.accessor((row) => row.amount, {
      id: 'Amount',
      cell: (info) => (
        <span className="text-renaissance-black text-sm">
          {info.getValue()}
        </span>
      ),
    }),

    columnHelper.accessor((row) => 'date', {
      id: 'date',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div className="text-renaissance-black capitalize">
            <span className="block text-sm ">
              {moment(value?.created_at).format('DD MMM yyyy')}
            </span>
            <span className="text-[10px] text-gray-2">
              {moment(value?.created_at).format('hh:mm Z')}
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => row.status, {
      id: 'payment method',
      cell: (info) => (
        <span className="text-renaissance-black text-sm block">
          {info.getValue()}
        </span>
      ),
    }),

    // columnHelper.accessor((row) => 'status', {
    //   id: 'status',
    //   cell: (info) => {
    //     const value = info.row.original;
    //     const status = value?.paymentStatus;

    //     let statusStyle;

    //     switch (status) {
    //       case 'pending':
    //         statusStyle = 'bg-orange-2 text-white';
    //         break;
    //       case 'rejected':
    //         statusStyle = 'bg-red-light text-red-1';
    //         break;
    //       case 'confirmed':
    //         statusStyle = 'bg-green-light text-[#158957]';
    //         break;
    //       default:
    //         statusStyle = 'bg-orange-2 text-white';
    //         break;
    //     }
    //     return (
    //       <div
    //         className={`text-[10px] w-fit text-center py-1 !px-2 ${statusStyle} rounded-xl`}
    //       >
    //         {status}
    //       </div>
    //     );
    //   },
    // }),
  ];

  const firstName = data?.data?.user?.user_metadata?.firstname;
  const lastName = data?.data?.user?.user_metadata?.lastname;

  const queryString = encodeURIComponent(JSON.stringify(data?.data?.user));

  // console.log(data?.data?.user);

  return (
    <div className="p-6 lg:px-6 xl:px-12 lg:py-10">
      <GoBack />

      <section className="my-8">
        <Heading2 className="block !text-lg text-renaissance-black mb-1">
          User Details
        </Heading2>

        <p className="text-sm font-normal text-renaissance-black">
          Details of this user
        </p>

        <article className="flex items-center mt-6">
          <div className="w-20 h-20 text-3xl mr-2 rounded-full bg-avatarBg text-avatarText flex items-center justify-center ">
            {firstName?.[0]}
            {lastName?.[0]}
          </div>
          <H3 className="block font-bold text-2xl capitalize text-renaissance-black">
            {firstName} {lastName}
          </H3>
        </article>
      </section>

      <section>
        <Heading2 className="!text-xl text-renaissance-black mb-1">
          Personal Information
        </Heading2>

        <article className="mt-6 mb-12 max-w-4xl grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <span className="block capitalize mb-1 text-renaissance-gray-2 text-base">
              first name
            </span>
            <span className="text-base text-renaissance-black font-medium">
              {data?.data?.user?.user_metadata?.firstname}
            </span>
          </div>

          <div>
            <span className="block capitalize mb-1 text-renaissance-gray-2 text-base">
              Last Name
            </span>
            <span className="text-base text-renaissance-black font-medium">
              {data?.data?.user?.user_metadata?.lastname}
            </span>
          </div>

          <div>
            <span className="block capitalize mb-1 text-renaissance-gray-2 text-base">
              Email Address
            </span>
            <span className="text-base text-renaissance-black font-medium">
              {data?.data?.user?.email}
            </span>
          </div>

          <div>
            <span className="block capitalize mb-1 text-renaissance-gray-2 text-base">
              Phone Number
            </span>
            <span className="text-base text-renaissance-black font-medium">
              {data?.data?.user?.user_metadata?.phone}
            </span>
          </div>
        </article>

        <article className="flex flex-wrap items-center gap-4">
          <Link to={`/dashboard/${routes.customers}/${userId}/edit?data=${queryString}`}>
            <button className="flex items-center bg-primary-green capitalize text-white text-sm py-3 font-semibold !px-5 rounded-md">
              edit user details
            </button>
          </Link>

          <button
            onClick={() => setOpenDelete(true)}
            className="flex items-center bg-red-1 capitalize text-white text-sm py-3 font-semibold !px-5 rounded-md"
          >
            delete user
          </button>

          <Link to={`/${routes.dashboard_home}/${routes.session}/${routes.new}?userId=${userId}&username=${firstName + ' ' + lastName}`}>
            <button className="flex items-center bg-renaissance-black capitalize text-white text-sm py-3 font-semibold !px-5 rounded-md">
              create a session
            </button>
          </Link>
        </article>
      </section>

      <section className="mt-14 xl:w-11/12">
        <article>
          <Heading2 className="block !text-lg text-renaissance-black mb-1">
            Transaction Summary
          </Heading2>

          <p className="text-sm font-normal text-renaissance-black">
            This is an overview of all session
          </p>
        </article>

        <article className="mt-6 mb-4 border border-gray-6 rounded bg-white py-4 !px-2 md:!px-6 flex flex-wrap items-center gap-2 md:gap-x-6 lg:gap-x-8">
          {/* <h3 className="whitespace-nowrap uppercase text-xs md:text-sm  font-medium text-gray-1">
            {transactions?.data?.length} users
          </h3> */}

          <h3 className="whitespace-nowrap uppercase text-xs md:text-sm  font-medium text-gray-1">
            transaction history
          </h3>

          {/* <form className="py-2 !px-2 md:!px-6  lg:w-96 flex items-center gap-x-2 md:gap-x-4 border border-gray-3 rounded focus-within:border focus-within:border-dark-2">
            <img src={SearchGrayIcon} alt="search icon" />

            <input
              className="flex-1 border-0 outline-0 bg-transparent shadow-none text-sm"
              type="text"
              placeholder="Search User Transaction"
            />
          </form> */}
        </article>

        <article>
          {transactions?.count === 0 ? (
          <div className="p-6 rounded-lg bg-gray-100">
            <p className="mt-20 text-gray-500 text-2xl text-center font-medium">
              No Record Found
            </p>
          </div>
        ) : loading ? (
          <Loader loadingText="Loading Admin data" className="h-96 w-full" />
        ) : (
          <Tables 
            columns={columns} 
            data={dataTable || []}
            loading={loading}
            totalPage={totalPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage} 
            isPaginated={true}
          />
        )}
        </article>
      </section>

      {openDelete && (
        <Popup
          onClose={() => {
            setOpenDelete(false);
          }}
          heading="Delete"
          body={
            <div className="text-center pb-10">
              <img
                className="mx-auto"
                src={AlertDeleteIcon}
                alt="delete warning"
              />

              <h6 className="mt-6 mb-2 text-black text-2xl font-medium">
                Confirm Delete
              </h6>

              <p className="text-sm text-[#74787C]">
                Confirm you want to this user
              </p>

              <div className="mt-6 flex items-center gap-x-4 justify-center">
                <button
                  onClick={() => setOpenDelete(false)}
                  className="text-center bg-white border border-gray-2 capitalize text-renaissance-black text-sm font-semibold w-36 py-3 rounded-md"
                >
                  cancel
                </button>

                <button 
                onClick={handleDeleteUser}
                className="text-center bg-red-1 border border-gray-2 capitalize text-white text-sm font-semibold w-36 py-3 rounded-md">
                  {isDeleting ? 'Deleting...' : 'confirm delete'}
                </button>
              </div>
            </div>
          }
          backBtn={true}
        />
      )}
    </div>
  );
};

export default ViewUser;
