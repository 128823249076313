import React from 'react';
import { Button } from '@deposits/ui-kit-react';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';

import moment from 'moment';
import { GoldCoins, ChatIcon, EmergencyIcon } from '../../../assets/icons';

const ViewDetail = ({
  isLoading,
  closeView,
  request,
  approveRequest,
  openDeleteModal,
}) => {
  return (
    <div className="rounded-tl-2xl rounded-tr-2xl bg-neutral dark:bg-table-border-gray w-full lg:w-[620px] h-full lg:h-auto overflow-y-auto">
      <div className="bg-white flex px-2 lg:!px-8 py-5 justify-between ">
        <h4 className="font-bold text-xl text-renaissance-black">
          Request Details
        </h4>
        <button className="inline dark:hidden" onClick={closeView}>
          <CloseSvg />
        </button>
      </div>
      <div className="overflow-y-scroll bg-neutral dark:bg-table-border-gray py-9 px-2 lg:!px-8">
        <section className="">
          <h5 className="mb-3 font-bold text-lg  text-renaissance-black ">
            Booking Details
          </h5>
          <div className="bg-white text-black border border-renaissance-gray-2 p-4  xl:p-6  rounded-lg">
            <div className="flex item-start lg:items-center">
              <div className="mr-8 lg:mr-4 xl:mr-8 ">
                <span className="text-base block font-medium text-center">
                  {moment(request?.session?.date).format('ddd')}
                </span>
                <span className="text-4xl font-semibold">
                  {moment(request?.session?.date).format('D')}
                </span>
              </div>
              {/* details */}
              <div className=" flex-1">
                <div className="flex flex-col lg:flex-row gap-y-1 items-start justify-between ">
                  <div>
                    <span className="font-semibold text-sm">
                      {request?.session?.type} Session
                    </span>
                    <div className="flex gap-x-1 items-center  font-normal">
                      <span className="block text-[10px]">
                        {moment
                          .utc(request?.session?.startTime)
                          .format('h:mm A')}
                      </span>
                      <span>-</span>
                      <span className="block text-[10px]">
                        {moment.utc(request?.session?.endTime).format('h:mm A')}
                      </span>
                    </div>
                  </div>
                  <div className="bg-primary-green text-white rounded w-fit !px-4 py-1.5  text-[10px] font-semibold">
                    {request?.session?.duration}
                  </div>
                </div>
                <div className="  mt-4 flex flex-col lg:flex-row lg:items-end gap-y-1 justify-between">
                  <div>
                    <span className="text-[10px]">Amount</span>
                    {request?.session?.payment === 'coin balance' ? (
                      <div className="flex items-center gap-x-2">
                        <img src={GoldCoins} alt="gold coins" />
                        <span className="font-semibold text-base text-renaissance-black">
                          {request?.session?.amount} Coins
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <span className="self-start px-1 text-base lg:text-2xl ">
                          £
                        </span>
                        <span className="font-semibold text-base lg:text-2xl  text-renaissance-black">
                          {request?.session?.amount}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="flex items-end gap-2">
                    <span className="block text-[10px] font-medium">
                      Access Code:
                    </span>
                    <span className="block font-semibold text-sm">
                      {' '}
                      {request?.session?.passcode}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-4">
          <h5 className="mb-3 font-bold text-lg  text-renaissance-black ">
            Reason for cancelling
          </h5>
          <div className="bg-white rounded-3xl !px-6 py-8">
            <div className="flex items-center gap-2">
              <img src={EmergencyIcon} alt="family emergency" />
              <span className="block text-sm lg:text-base font-semibold">
                {request?.reason}
              </span>
            </div>
            <div className="mt-6 flex items-start gap-2">
              <div>
                <img src={ChatIcon} alt="additional comments" />
              </div>
              <div className="flex-1">
                <span className="block text-base lg:text-lg">
                  Additional Comments
                </span>
                <p className=" mt-2 lg:w-2/3 whitespace-normal normal-case ">
                  {request?.details}
                </p>
              </div>
            </div>
          </div>
        </section>

        {request?.status === 'pending' && (
          <div className="mt-6 flex flex-col md:flex-row gap-2">
            <Button
              disabled={isLoading}
              onClick={approveRequest}
              className="!bg-primary-green !w-full !border-0 !px-8 !text-white"
              size="xlarge"
            >
              {isLoading ? 'Approving..' : 'Approve'}
            </Button>
            <Button
              onClick={openDeleteModal}
              className="!bg-red-1 !w-full !border-0 !px-8 !text-primary-white"
              size="xlarge"
            >
              Decline
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewDetail;
