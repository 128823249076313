import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../router/routes';
import Loader from '../../../components/Loader';
import { GoBack } from '../../../components/elements';
import { ManageUser } from '../../../components/sections';
import { AddWhiteIcon, SearchGrayIcon } from '../../../assets/icons';

import moment from 'moment';
import Tables from '../../../components/Tables';
import { createColumnHelper } from '@tanstack/react-table';

import { registeredUser } from '../../../utils/dummyData';
import { useCustomers } from '../../../helpers/hooks/queries/useCustomers';



export const filterData = (data) => data?.data?.users?.filter(
  (user) => ((!user?.user_metadata?.role || user?.user_metadata?.role === 'user') && user?.user_metadata?.firstname)
)

const limit = 20;

const Customers = () => {
  const lastUser = registeredUser[registeredUser?.length - 1];

  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearchedPage, setCurrentSearchedPage] = useState(1);
  const [searchValue, setSearchValue] = useState('')

  const { data: dataTotal } = useCustomers()
  const { data: allUsers, isLoading: loading } = useCustomers(currentPage, limit);

  const dataTotalMemo = useMemo(
    () => filterData(dataTotal),
    [dataTotal?.data?.users]
  );

  const dataTable = useMemo(
    () => filterData(allUsers),
    [allUsers?.data?.users]
  );


  const searched = searchValue
    && dataTotalMemo
      ?.filter(item => (
        item?.user_metadata?.firstname?.toLowerCase()?.includes(searchValue?.toLowerCase())
        || item?.user_metadata?.lastname?.toLowerCase()?.includes(searchValue?.toLowerCase())
      ))


  const searchedPerPage = (searched
    && searched?.length >= limit)
    ? searched
      ?.filter((item, index) => {
        const skip = (currentSearchedPage - 1) * limit;
        return (
          index >= skip && index < (skip + limit)
        );
      })
    : searched

  const totalPage = dataTotal?.data?.users
    && Math.ceil(dataTotal?.data?.users?.length / limit);

  const totalSearchedPage = Array.isArray(searched)
    && Math.ceil(searched?.length / limit)



  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => 'user name', {
      id: 'Name of user',
      cell: (info) => {
        const value = info.row.original;

        const firstname = value?.user_metadata?.firstname;
        const lastname = value?.user_metadata?.lastname;
        const userId = value?.id;
        const role = value?.user_metadata;

        return (
          <Link to={`/dashboard/customers/${userId}/profile?data=${value}`}>
            <div className="text-primary-blue font-normal capitalize hover:underline cursor-pointer" >
              <span className="text-sm">{firstname}</span>
              <span className="ml-1  text-sm">{lastname}</span>
            </div>
          </Link>
        );
      },
    }),

    columnHelper.accessor((row) => 'email address', {
      id: 'email address',
      cell: (info) => {
        const value = info.row.original;
        const email = `${value?.email}`;

        return (
          <span title={email} className="text-renaissance-black font-normal text-sm normal-case">
            {email?.length > 20 ? `${email.substring(0, 15)}...` : email}
          </span>
        );
      },
    }),

    // columnHelper.accessor((row) => 'no. of session', {
    //   id: 'no. of session',
    //   cell: (info) => {
    //     const value = info.row.original;

    //     return (
    //       <span className="text-renaissance-black font-normal text-sm capitalize">
    //         {value?.sessionCount ?? 'Unknown'}
    //       </span>
    //     );
    //   },
    // }),

    columnHelper.accessor((row) => 'date registered', {
      id: 'date registered',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div className="text-gray-9 capitalize">
            <span className="block text-sm">
              {moment(value?.created_at).format('MMM DD, yyyy')}
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => 'status', {
      id: 'status',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div
            className={` capitalize ${value?.email_confirmed_at
              ? 'text-green-1 text-sm'
              : 'bg-orange-2 text-xs text-white !px-2 py-1 rounded-[10px] w-fit'
              }`}
          >
            <span>{value?.email_confirmed_at ? 'verified' : 'unverified'}</span>
          </div>
        );
      },
    }),

    columnHelper.accessor(() => 'more', {
      id: 'more',
      header: () => '',
      cell: (info) => {
        const { row } = info;
        const user = row?.original;

        return (
          <div>
            <ManageUser user={user} lastUser={lastUser} />
          </div>
        );
      },
    }),
  ];

  return (
    <div className="p-6 lg:px-6 xl:px-12 lg:py-10">
      <GoBack />

      <section className="mt-8 md:flex items-center justify-between">
        <div className="mb-2 md:mb-0">
          <h2 className="font-semibold text-base text-renaissance-black mb-1">
            All User
          </h2>
          <p className="text-sm font-normal text-renaissance-black">
            This show the total registered users on the platform
          </p>
        </div>
        <Link to={`/dashboard/${routes.customers}/${routes.addUsers}`}>
          <button className="flex items-center bg-renaissance-black capitalize text-white text-sm py-3 !px-5 font-semibold  rounded-md">
            <img src={AddWhiteIcon} alt="add user icon" />
            <span className="ml-2">add new user</span>
          </button>
        </Link>
      </section>

      <section className="mt-6 mb-4 border border-gray-6 rounded bg-white py-2 !px-2 md:!px-6 flex  items-center gap-x-2 md:gap-x-6 lg:gap-x-8">
        <h3 className="text-xs md:text-sm font-medium text-gray-1 whitespace-nowrap min- w-[80px] ">
          {dataTotalMemo?.length} users
        </h3>

        <form className="py-2 !px-2 md:!px-6  lg:w-96 flex items-center gap-x-2 md:gap-x-4 border border-gray-3 rounded focus-within:border focus-within:border-dark-2">
          <img src={SearchGrayIcon} alt="search icon" />

          <input
            className="flex-1 border-0 outline-0 bg-transparent shadow-none text-sm"
            type="text"
            placeholder="Search User"
            onChange={e => setSearchValue(e.target.value)}
          />
        </form>
        {searchValue && (
          <p className='text-gray-2 text-sm sm:text-initial '>  {searched?.length} results found</p>
        )}
      </section>

      <section>
        {dataTotalMemo?.length < 1 ? (
          <div className="p-6 rounded-lg bg-gray-100">
            <p className="my-20 text-gray-500 text-2xl text-center font-medium">
              No Record Found
            </p>
          </div>
        ) : loading ? (
          <Loader loadingText="Loading Admin data" className="h-96 w-full" />
        ) : (
          <Tables
            isPaginated
            columns={columns}
            data={searchValue ? searchedPerPage : dataTable}
            loading={loading}
            totalPage={searchValue ? totalSearchedPage : totalPage}
            currentPage={searchValue ? currentSearchedPage : currentPage}
            setCurrentPage={searchValue ? setCurrentSearchedPage : setCurrentPage}
          />
        )}
      </section>
    </div>
  );
};

export default Customers;
