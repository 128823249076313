import { useMutation, useQuery } from 'react-query';
import { supabase } from '../../../utils/supabaseConfig';

export const useAdminUsers = () => {
  const query = useQuery(['admin'], () => {
    return supabase.auth.admin.listUsers();
  });
  return query;
};

export const useDeleteAdmin = () => {
  const mutation = useMutation(['admin'], (id) => {
    return supabase.auth.admin.deleteUser(id);
  });

  return mutation;
};

// export const useAddAdmin = () => {
//   const mutation = useMutation(['admin'], (email, payload) => {
//     return supabase.auth.admin.inviteUserByEmail(email, payload);
//   });

//   return mutation;
// };

export const useEditAdmin = (id, payload) => {
  const mutation = useMutation(['admin'], () => {
    return supabase.auth.admin.updateUserById(id, payload);
  });

  return mutation;
};
