import { CloseGrayIcon } from '../../assets/icons';

const Popup = ({ heading, body, backBtn, onClose }) => {
  return (
    <div className="bg-dark-1/10 h-full w-full fixed top-0 bottom-0 left-0 z-50 flex items-center justify-center !px-4 lg:px-0">
      <div className="bg-white h-fit w-full md:w-[500px] md:rounded-lg">
        <div className="flex items-center justify-between p-6">
          <h6 className="font-semibold text-lg text-dark-2"> {heading}</h6>

          {backBtn && (
            <button onClick={onClose}>
              <img src={CloseGrayIcon} alt="close icon" />
            </button>
          )}
        </div>

        <div>{body}</div>
      </div>
    </div>
  );
};

export default Popup;
