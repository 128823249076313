import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../router/routes';

import { GoBack } from '../../../components/elements';
import { TransactionCard } from '../../../components/sections';
import { Heading2 } from '../../../components/Headings';
import Tables from '../../../components/Tables';
import { createColumnHelper } from '@tanstack/react-table';

import moment from 'moment';
import { useTotalCoinTransactions, useTotalStripeTransactions, useTransactions } from '../../../helpers/hooks/queries/useTransactions';

import {
  rightArrowIcon,
  CoinBlueIcon,
  CoinGreen,
  CoinOrange,
} from '../../../assets/icons';
import Loader from '../../../components/Loader';

const Transactions = () => {
  const { data: transactions, isLoading: loading } = useTransactions();
  const { data: totalCoin } = useTotalCoinTransactions();
  const { data: totalStripe } = useTotalStripeTransactions();

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => 'user', {
      id: 'Name of user',
      cell: (info) => {
        const value = info.row.original;
        const name = value?.username;
        const userId = value?.user_id;

        return (
          <Link to={`/dashboard/customers/${userId}/profile`}>
            <div className="text-primary-blue font-normal capitalize hover:underline cursor-pointer">
              <span className="text-sm ">{name}</span>
            </div>
          </Link>
        );
      },
    }),

    columnHelper.accessor((row) => row.description, {
      id: 'description',
      cell: (info) => (
        <span className="text-renaissance-black text-sm">
          {info.getValue()}
        </span>
      ),
    }),

    columnHelper.accessor((row) => 'date', {
      id: 'date',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div className="text-renaissance-black capitalize">
            <span className="block text-sm ">
              {moment(value?.created_at).format('DD MMM yyyy')}
            </span>
            <span className="text-[10px] text-gray-2">
              {moment(value?.created_at).format('hh:mm Z')}
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => row.amount, {
      id: 'Amount',
      cell: (info) => (
        <span className="text-renaissance-black text-sm">
          {info.getValue()}
        </span>
      ),
    }),

    columnHelper.accessor((row) => 'Payment Method', {
      id: 'Payment Method',
      cell: (info) => {
        const value = info.row.original;

        return (
          <span className="text-renaissance-black text-sm block">
            {value?.status}
          </span>
        );
      },
    }),
  ];

  const dataTable = useMemo(() => {
    return transactions?.data?.slice(0, 6);
  }, [transactions?.data]);


  return (
    <div className="p-6 lg:px-6 xl:px-12 lg:py-10">
      <GoBack />

      <section className="my-8">
        <Heading2 className="block !text-lg text-renaissance-black mb-1">
          Transaction Summary
        </Heading2>

        <p className="text-sm font-normal text-renaissance-black">
          Total overview of Le Pole
        </p>

        <article className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
          <TransactionCard
            icon={CoinGreen}
            bgColor="bg-green-light"
            title="Total Transactions"
            figures={transactions?.count}
          />

          <TransactionCard
            icon={CoinBlueIcon}
            bgColor="bg-blue-light"
            title="Coin-based Transactions "
            figures={totalCoin?.data || '...'}
          />

          <TransactionCard
            icon={CoinOrange}
            bgColor="bg-orange-light"
            title="Stripe-based Transactions"
            figures={totalStripe?.data || '...'}
          />
        </article>
      </section>

      <section className="  mt-6 mb-4">
        <Heading2 className="block !text-lg text-renaissance-black mb-1">
          Transaction
        </Heading2>

        <p className="text-sm font-normal text-renaissance-black">
          This is an overview of all transactions
        </p>

        <article className="mt-4 flex items-center justify-between gap-x-8 py-2 !px-2 md:!px-6  border border-gray-6 rounded">
          <div>
            <h3 className="uppercase text-xs md:text-sm font-medium text-gray-1 whitespace-nowrap">
              recent transactions
            </h3>
          </div>

          <div>
            <Link
              to={`/dashboard/${routes.transactions}/${routes.allTransactions}`}
            >
              <button className="flex items-center justify-between border border-gray-4 rounded py-1 !px-2 md:py-2 md:!px-4  font-medium capitalize  text-renaissance-black">
                <span className="text-sm">See all transactions</span>
                <img className="ml-3" src={rightArrowIcon} alt="go to page" />
              </button>
            </Link>
          </div>
        </article>
      </section>

      <section>
        {transactions?.data?.length < 1 ? (
          <div className="p-6 rounded-lg bg-gray-300">
            <p className="mt-20 text-gray-500 text-2xl text-center font-medium">
              No Transactions yet
            </p>
          </div>
        ) : loading ? (
          <Loader loadingText="Loading Admin data" className="h-96 w-full" />
        ) : (
          <Tables columns={columns} data={dataTable || []} loading={loading} />
        )}
      </section>
    </div>
  );
};

export default Transactions;
