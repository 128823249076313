import React from 'react';

const CancelSummary = ({ icon, heading, value }) => {
  return (
    <div className="p-6 bg-white border border-gray-4 rounded-lg">
      <div>
        <img src={icon} alt="cancel-request" />
      </div>

      <div className="mt-8">
        <h3 className="text-sm text-prinary-gray">{heading}</h3>
        <span className="font-semibold text-3xl text-renaissance-black">
          {value}
        </span>
      </div>
    </div>
  );
};

export default CancelSummary;
