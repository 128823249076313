import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../../router/routes';

const SettingsTab = () => {
  const { pathname } = useLocation();

  const settingsLink = [
    {
      id: 1,
      title: 'profile settings',
      link: `/dashboard/${routes.settings}`,
    },
    {
      id: 2,
      title: 'team members',
      link: `/dashboard/${routes.settings}/${routes.team}`,
    },
    // {
    //   id: 3,
    //   title: 'security',
    //   link: `/dashboard/${routes.settings}/${routes.security}`,
    // },
  ];

  return (
    <ul className="flex items-center gap-x-8 capitalize text-renaissance-black text-base">
      {settingsLink.map((item) => (
        <Link key={item.id} to={item.link}>
          <li
            className={`py-1
              ${
                pathname === item.link ? 'border-b-2 border-primary-green ' : ''
              }
            `}
          >
            {item.title}
          </li>
        </Link>
      ))}
    </ul>
  );
};

export default SettingsTab;
