import React, { useMemo, useState } from 'react';

import { GoBack } from '../../../components/elements';
import { Heading2 } from '../../../components/Headings';
import Tables from '../../../components/Tables';
import { createColumnHelper } from '@tanstack/react-table';

import moment from 'moment';
import {
  useTransactions,
  usePaginatedTransactions,
} from '../../../helpers/hooks/queries/useTransactions';

import {
  SearchGrayIcon,
  FilterBlackIcon,
  DownArrowIcon,
} from '../../../assets/icons';

import Loader from '../../../components/Loader';
import MultiDropdownSort from '../../../components/MultiDropdownSort';
import { Link } from 'react-router-dom';
import { transactionSortOptions } from '../../../utils/dummyData';

const AllTransactions = () => {
  const [limit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOptions, setSortOptions] = useState({
    sortBy: 'created_at',
    asc: false
  });


  const skip = (currentPage - 1) * limit;

  const { data: totalTransaction } = useTransactions();


  const { data: transactions, isLoading: loading } = usePaginatedTransactions(
    limit,
    skip,
    currentPage,
    sortOptions.sortBy,
    sortOptions.asc,
  );


  const handleSortOptions = (sortBy, asc) => {
    setSortOptions({
      sortBy,
      asc
    });
  }
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => 'user', {
      id: 'Name of user',
      cell: (info) => {
        const value = info.row.original;
        const name = value?.username;
        const userId = value?.user_id;


        return (
          <Link to={`/dashboard/customers/${userId}/profile`}>
            <div className="text-primary-blue font-normal capitalize hover:underline cursor-pointer">
              <span className="text-sm ">{name}</span>
            </div>
          </Link>
        );
      },
    }),

    columnHelper.accessor((row) => row.description, {
      id: 'description',
      cell: (info) => (
        <span className="text-renaissance-black text-sm">
          {info.getValue()}
        </span>
      ),
    }),

    columnHelper.accessor((row) => 'date', {
      id: 'date',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div className="text-renaissance-black capitalize">
            <span className="block text-sm ">
              {moment(value?.created_at).format('DD MMM yyyy')}
            </span>
            <span className="text-[10px] text-gray-2">
              {moment(value?.created_at).format('hh:mm Z')}
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => row.amount, {
      id: 'Amount',
      cell: (info) => (
        <span className="text-renaissance-black text-sm">
          {info.getValue()}
        </span>
      ),
    }),

    columnHelper.accessor((row) => 'Payment Method', {
      id: 'Payment Method',
      cell: (info) => {
        const value = info.row.original;

        return (
          <span className="text-renaissance-black text-sm block">
            {value?.status}
          </span>
        );
      },
    }),
  ];

  const dataTable = useMemo(() => {
    return transactions?.data;
  }, [transactions?.data]);

  const totalPage =
    totalTransaction?.data && Math.ceil(totalTransaction?.data?.length / limit);




  return (
    <div className="p-6 lg:px-6 xl:px-12 lg:py-10">
      <GoBack />
      <section className="  mt-6 mb-4">
        <Heading2 className="capitalize block !text-lg text-renaissance-black mb-1">
          Transaction summary
        </Heading2>

        <p className="text-sm font-normal text-renaissance-black">
          This is an overview of all transactions
        </p>

        <article className="mt-4 md: flex items-center justify-between md: gap-8 py-2 !px-2 md:!px-6  border border-gray-6 rounded">
          <div className="flex-1 md:flex items-center md:gap-8">
            <h3 className="uppercase text-xs md:text-sm font-medium text-gray-1 whitespace-nowrap">
              transaction history
            </h3>

            {/* <form className="my-3 md:my-0 lg:w-1/2  flex items-center gap-x-2 md:gap-x-4  py-2 !px-2 md:!px-6 border border-gray-3 rounded focus-within:border focus-within:border-primary-blue">
              <img src={SearchGrayIcon} alt="search icon" />

              <input
                className="flex-1 border-0 outline-0 bg-transparent shadow-none text-sm"
                type="text"
                placeholder="Search User"
              />
            </form> */}
          </div>

          <div className="flex items-center justify-start md:justify-end gap-4 md:gap-8  md:mt-0">
            <MultiDropdownSort options={transactionSortOptions} handleSortOptions={handleSortOptions} />
          </div>
        </article>
      </section>

      <section>
        {transactions?.count === 0 ? (
          <div className="p-6 rounded-lg bg-gray-100">
            <p className="mt-20 text-gray-500 text-2xl text-center font-medium">
              No Record Found
            </p>
          </div>
        ) : loading ? (
          <Loader loadingText="Loading Admin data" className="h-96 w-full" />
        ) : (
          <Tables
            columns={columns}
            data={dataTable || []}
            loading={loading}
            totalPage={totalPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isPaginated={true}
          />
        )}
      </section>
    </div>
  );
};

export default AllTransactions;
