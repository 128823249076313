import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../router/routes';

import { GoBack } from '../../../components/elements';
import { ManageUser } from '../../../components/sections';
import {
  AddWhiteIcon,
  DownArrowIcon,
  FilterBlackIcon,
  SearchGrayIcon,
} from '../../../assets/icons';

import moment from 'moment';
import Tables from '../../../components/Tables';
import { createColumnHelper } from '@tanstack/react-table';

import {
  allSessions,
  registeredUser,
  sessionsSortOptions,
} from '../../../utils/dummyData';
import ModalContainer from '../../../components/layouts/ModalContainer';
import { SelectPaymentOption } from '../../../components/Modals';
import {
  useAllSessions,
  usePaginatedAllSessions,
} from '../../../helpers/hooks/queries/useSessions';
import Loader from '../../../components/Loader';
import { useCustomers } from '../../../helpers/hooks/queries/useCustomers';
import { filterData } from '../customers/Users';
import MultiDropdownSort from '../../../components/MultiDropdownSort';

const limit = 20;

const AllSessions = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOptions, setSortOptions] = useState({
    sortBy: 'created_at',
    asc: false,
  });

  const { data: dataTotal } = useAllSessions();
  const { data, isLoading } = usePaginatedAllSessions(
    currentPage,
    limit,
    sortOptions.sortBy,
    sortOptions.asc,
  );
  const { data: allUsers } = useCustomers();

  const dataUsers = useMemo(
    () => filterData(allUsers),
    [allUsers?.data?.users],
  );

  const dataTable = useMemo(() => data?.data, [data?.data]);

  const dataTotalMemo = useMemo(() => dataTotal?.data, [dataTotal?.data]);

  const totalPage = dataTotalMemo && Math.ceil(dataTotalMemo?.length / limit);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const handleSortOptions = (sortBy, asc) => {
    setSortOptions({
      sortBy,
      asc,
    });
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => 'Session', {
      id: 'Session Type',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div className="text-priBlack  text-sm capitalize">
            <span> {value.type} </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => 'user name', {
      id: 'User',
      cell: (info) => {
        const value = info.row.original;
        const name = value?.username;
        const userId = value?.user_id;

        return (
          <Link to={`/dashboard/customers/${userId}/profile`}>
            <div className="text-primary-blue font-normal capitalize hover:underline cursor-pointer">
              <span className=""> {name} </span>
            </div>
          </Link>
        );
      },
    }),

    columnHelper.accessor((row) => 'Date', {
      id: 'Date ',
      cell: (info) => {
        const { date, time } = info.row.original;

        return (
          <div className="text-sm">
            <span className=" text-sm text-priBlack">
              {moment.utc(date).format('Do MMMM, YYYY')}
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => row.role, {
      id: 'Hours',
      cell: (info) => (
        <span className="text-priBlack text-sm ">
          {info.row.original.duration}
        </span>
      ),
    }),

    columnHelper.accessor((row) => row.role, {
      id: 'Start Time',
      cell: (info) => (
        <span className="text-priBlack text-sm">
          {moment.utc(info.row.original.startTime).format('h:mm A')}
        </span>
      ),
    }),

    columnHelper.accessor((row) => row.role, {
      id: 'End Time',
      cell: (info) => (
        <span className="text-priBlac text-sm">
          {moment.utc(info.row.original.endTime).format('h:mm A')}
        </span>
      ),
    }),

    columnHelper.accessor((row) => 'status', {
      id: 'Status',
      cell: (info) => {
        const { isCancelled } = info.row.original;

        return (
          <p
            className={`px-2 py-1 text-xs font-normal w-max items-center gap-2`}
          >
            <span />
            {isCancelled ? 'Yes' : 'No'}
          </p>
        );
      },
    }),

    // columnHelper.accessor(() => 'actions', {
    //   id: 'Actions',
    //   cell: (info) => {
    //     const { row } = info;
    //     const applicant = row?.original;

    //     return (
    //       <button>
    //         <p className='underline hover:no-underline'>Reschedule</p>
    //       </button>
    //     );
    //   },
    // }),
  ];

  return (
    <>
      {modalOpen && (
        <ModalContainer modalOpen={modalOpen} toggleModal={toggleModal}>
          <SelectPaymentOption
            toggleModal={toggleModal}
            usersData={dataUsers}
          />
        </ModalContainer>
      )}

      <div className="p-6 lg:px-6 xl:px-12 lg:py-10">
        <GoBack />

        <section className="mt-8 md:flex items-center justify-between">
          <div className="mb-2 md:mb-0">
            <h2 className="font-semibold text-base text-renaissance-black mb-1">
              Session History
            </h2>
            <p className="text-sm font-normal text-renaissance-black">
              This is an overview of all session
            </p>
          </div>

          <button
            onClick={toggleModal}
            className="flex items-center bg-renaissance-black capitalize text-white text-sm py-3 !px-5 font-semibold  rounded-md"
          >
            <img src={AddWhiteIcon} alt="add user icon" />
            <span className="ml-2">Create A Session</span>
          </button>
        </section>

        <section className="mt-6 mb-4 justify-between border border-gray-6 rounded bg-white py-2 !px-2 md:!px-6 flex items-center gap-x-2 md:gap-x-6 lg:gap-x-8">
          <h3 className="text-xs md:text-sm font-medium text-gray-1 whitespace-nowrap justify-">
            ALL SESSIONS
          </h3>

          <div className="flex">
            <div className="flex gap-x-4 items-center">
              <MultiDropdownSort
                options={sessionsSortOptions}
                handleSortOptions={handleSortOptions}
              />
            </div>

            {/* <div className="py-2 !px-2 ml-10 lg:w-16 flex items-center gap-x-2 md:gap-x-4 border border-gray-3 rounded focus-within:border focus-within:border-dark-2">
              <input
                className="flex-1 border-0 outline-0 bg-transparent shadow-none text-sm"
                type="number"
                onChange={pageChangehandler}
                placeholder="Page"
              />
            </div> */}
          </div>
        </section>

        <section>
          {data?.data?.length === 0 ? (
            <div className="p-6 rounded-lg bg-gray-100">
              <p className="my-20 text-gray-500 text-2xl text-center font-medium">
                No Record Found
              </p>
            </div>
          ) : isLoading ? (
            <Loader loadingText="Loading Admin data" className="h-60 w-full" />
          ) : (
            <Tables
              columns={columns}
              data={dataTable || []}
              loading={isLoading}
              totalPage={totalPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isPaginated={true}
            />
          )}
        </section>
      </div>
    </>
  );
};

export default AllSessions;
