import React from 'react';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import { CheckCircleGreen, CheckCircleRed } from '../../../assets/icons';
import { useQueryClient } from 'react-query';

const RequestStatus = ({ request, status, closeStatus }) => {
  const queryClient = useQueryClient();

  const handleCloseStatus = () => {
    queryClient.invalidateQueries('cancel-session');
    closeStatus();
  };

  return (
    <div className="w-full lg:w-[620px] h-full lg:h-auto overflow-y-auto rounded-tl-2xl rounded-tr-2xl bg-neutral ">
      <div className="flex px-2 lg:!px-8 py-5 justify-between bg-white">
        <h4 className="font-bold text-xl text-renaissance-black">
          {status === 'approved' ? 'Approved' : 'Declined'}
        </h4>
        <button onClick={handleCloseStatus}>
          <CloseSvg />
        </button>
      </div>

      {status === 'approved' ? (
        <div className="text-center py-9">
          <img className="mx-auto" src={CheckCircleGreen} alt="approved" />

          <h5 className="mt-6 mb-2 text-xl lg:text-2xl font-medium">
            Request Approved
          </h5>

          <span className="text-sm text-gray-400 normal-case">
            Your have approved this request.
          </span>

          <p className="normal-case mt-2 mb-8 text-sm text-gray-400 lg:w-2/3 mx-auto whitespace-normal">
            A refund of {request?.session?.amount} coins has been refunded back
            to the customer’s wallet.
          </p>

          <button
            onClick={handleCloseStatus}
            className="capitalize underline text-renaissance-black text-sm"
          >
            close
          </button>
        </div>
      ) : (
        <div className="text-center py-9">
          <img className="mx-auto" src={CheckCircleRed} alt="approved" />

          <h5 className="mt-6 mb-2 text-xl lg:text-2xl font-medium">
            Request Declined
          </h5>

          <span className="normal-case text-sm text-gray-400">
            Your have declined this request.
          </span>

          <p className="normal-case mt-2 mb-8 text-sm text-gray-400 lg:w-2/3 mx-auto whitespace-normal">
            An update has been sent to your mail.
          </p>

          <button
            onClick={handleCloseStatus}
            className="capitalize underline text-renaissance-black text-sm"
          >
            close
          </button>
        </div>
      )}
    </div>
  );
};

export default RequestStatus;
