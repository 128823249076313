import Tables from '../../Tables';
import { createColumnHelper } from '@tanstack/react-table';

import { transactions } from '../../../utils/dummyData';

import moment from 'moment';
import { useTransactions } from '../../../helpers/hooks/queries/useTransactions';
import Loader from '../../Loader';

const TransactionTable = () => {
  const { data: transactionData, isLoading } = useTransactions()


  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => 'Name of user', {
      id: 'Name of user',
      cell: (info) => {
        const value = info.row.original;
        const username = value?.username;

        return (
          <div className="text-black font-normal capitalize">
            <span className="text-sm ">{username}</span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => row.amount, {
      id: 'Amount',
      cell: (info) => (
        <span className="text-renaissance-black text-sm">
          £ {info.getValue()}
        </span>
      ),
    }),

    columnHelper.accessor((row) => 'date', {
      id: 'date',
      cell: (info) => {
        const value = info.row.original;

        return (
          <div className="text-renaissance-black capitalize">
            <span className="block text-sm ">
              {moment(value?.created_at).format('DD MMM yyyy')}
            </span>
            <span className="text-[10px] text-gray-2">
              {moment(value?.created_at).format('hh:mm Z')}
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor((row) => row.description, {
      id: 'Description',
      cell: (info) => (
        <span className="text-renaissance-black text-sm">
          {info.getValue()}
        </span>
      ),
    }),
  ];




  return (
    <div>
      {transactionData?.data?.length === 0 ? (
        <div className="p-6 rounded-lg bg-gray-100">
          <p className="my-20 text-gray-500 text-2xl text-center font-medium">
            No Record Found
          </p>
        </div>
      ) : isLoading ? (
        <Loader loadingText="Loading Admin data" className="h-60 w-full" />
      ) : (
        <Tables
          columns={columns}
          data={
            transactionData?.data
              ?.filter(item => item?.username)
              ?.slice(0, 4)
            || []
          }
        />
      )}
    </div>
  );
};

export default TransactionTable;
