import React, { useState } from 'react';

import { Button } from '@deposits/ui-kit-react';
import { GoBack } from '../../../components/elements';
import { SettingsTab } from '../../../components/sections';

import { useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import { supabase } from '../../../utils/supabaseConfig';
import { toast } from 'react-toastify';

const Settings = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const [cookies] = useCookies(['lepole_admin']);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const watchPassword = watch('password', '');

  const eightCharsOrMore = /.{8,}/g; // eight characters or more
  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9

  const passwordTracker = {
    uppercase: watchPassword.match(atLeastOneUppercase),
    number: watchPassword.match(atLeastOneNumeric),
    specialChar: watchPassword.match(atLeastOneSpecialChar),
    eightCharsOrGreater: watchPassword.match(eightCharsOrMore),
  };

  const PasswordChecker = ({ text, checker }) => {
    return (
      <span
        className={`${
          checker ? ' text-gray-2/50 line-through' : 'text-gray-2 '
        } block text-xs`}
      >
        {text}
      </span>
    );
  };

  const updatePassword = handleSubmit(async (data) => {
    setIsSubmitting(true);

    const res = await supabase.auth.updateUser({ password: data.password });

    if (res?.data?.user !== null && res?.error === null) {
      toast.success('Password Update successful.');
      setIsSubmitting(false);

      reset();
    } else {
      toast.error('Password Update Not Successful.');
      setIsSubmitting(false);
    }
  });

  return (
    <div className="p-6 lg:p-6 xl:px-12">
      <SettingsTab />

      <section className="mt-8">
        <GoBack />

        <form className="mt-6 w-full lg:w-2/3 grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <label className="block capitalize text-xs mb-1">first name</label>

            <input
              disabled
              className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
              type="text"
              placeholder={cookies?.lepole_admin?.firstname}
              name="firstName"
              {...register('firstName', { required: true })}
            />
          </div>

          <div>
            <label className="block capitalize text-xs mb-1">last name</label>

            <input
              disabled
              className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
              type="text"
              placeholder={cookies?.lepole_admin?.lastname}
              name="lastName"
              {...register('lastName', { required: true })}
            />
          </div>

          <div>
            <label className="block capitalize text-xs mb-1">
              email address
            </label>

            <input
              disabled
              className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
              type="email"
              placeholder={cookies?.lepole_admin?.email}
              name="email"
              {...register('email', {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              })}
            />
          </div>

          <div>
            <label className="block capitalize text-xs mb-1">
              phone number
            </label>

            <input
              disabled
              className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
              type="tel"
              placeholder={cookies?.lepole_admin?.phone}
              name="phone"
            />
          </div>

          <div>
            <label className="block capitalize text-xs mb-1">password</label>
            <input
              name="password"
              className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
              type="password"
              placeholder="Enter password"
              {...register('password', {
                required: true,
                minLength: 8,
                pattern:
                  /^(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
              })}
            />
            {errors.password && (
              <div className="text-red-400 text-xs mt-1">
                Password must pass all checks to be valid
              </div>
            )}

            <div className="col-span-1 mt-1">
              <PasswordChecker
                text="minimum of 8 character long"
                checker={passwordTracker.eightCharsOrGreater}
              />
              <PasswordChecker
                text="at least one capital letter"
                checker={passwordTracker.uppercase}
              />
              <PasswordChecker
                text="at least one symbol"
                checker={passwordTracker.specialChar}
              />
              <PasswordChecker
                text="at least one number"
                checker={passwordTracker.number}
              />
            </div>
          </div>

          <div>
            <label className="block capitalize text-xs mb-1">
              confirm password
            </label>
            <input
              name="confirmpassword"
              className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
              type="password"
              placeholder="Confirm password"
              {...register('confirmpassword', {
                required: true,
                minLength: 8,
                validate: (value) =>
                  value === watchPassword || 'The passwords do not match',
              })}
            />
            {errors.confirmpassword && (
              <div className="text-red-400 text-xs mt-1">
                Password must be a match
              </div>
            )}
          </div>

          <div className="mt-4">
            <Button
              onClick={updatePassword}
              disabled={isSubmitting}
              className="!bg-primary-green !w-fit !border-0 !px-8 !text-primary-white"
              size="xlarge"
            >
              {isSubmitting ? 'Updating...' : 'Update details'}
            </Button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Settings;
