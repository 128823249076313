import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CloseGrayIcon } from '../../../assets/icons';

import { supabase } from '../../../utils/supabaseConfig';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { SelectField } from '@deposits/ui-kit-react';

const AddTeam = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [role, setRole] = useState('');

  const option = [
    {
      text: 'Admin',
      value: 'admin',
    },
  ];

  const addMember = handleSubmit(async (data) => {
    setIsSubmitting(true);

    const res = await supabase.auth.signUp({
      email: data.email,
      password: data.password,

      options: {
        emailRedirectTo: 'https://le-pole-admin-dashboard.vercel.app',
        data: {
          firstname: data.firstName,
          lastname: data.lastName,
          phone: data.phone,
          role: 'admin',
        },
      },
    });

    if (res?.data?.user !== null && res?.error === null) {
      toast.success(
        `Member added successfully. An email has been sent to  ${data.email}`,
      );
      queryClient.invalidateQueries('admin');
      onClose();
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
    }
  });

  return (
    <div className="bg-dark-1/10 h-full w-full fixed top-0 bottom-0 left-0 z-50 flex items-center justify-center !px-4 lg:px-0">
      <div className="bg-white h-fit w-full md:w-[500px] md:rounded-lg">
        <div className="flex items-center justify-between p-6">
          <h6 className="font-semibold text-lg text-dark-2">Add Team Member</h6>

          <button onClick={onClose}>
            <img src={CloseGrayIcon} alt="close icon" />
          </button>
        </div>

        <form
          onSubmit={addMember}
          className="pb-10 !px-2 md:!px-4 grid grid-cols-1 lg:grid-cols-2 gap-6"
        >
          <div className="col-span-2 md:col-span-1">
            <label className="block capitalize text-xs mb-1">first name</label>

            <input
              className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
              type="text"
              placeholder="Enter first name"
              name="firstName"
              {...register('firstName', { required: true })}
            />
            {errors.firstName && (
              <div className="text-red-400 text-xs mt-1">
                First name cannot be empty
              </div>
            )}
          </div>

          <div>
            <label className="block capitalize text-xs mb-1">last name</label>

            <input
              className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
              type="text"
              placeholder="Enter last name"
              name="firstName"
              {...register('lastName', { required: true })}
            />
            {errors.lastName && (
              <div className="text-red-400 text-xs mt-1">
                Last name cannot be empty
              </div>
            )}
          </div>

          <div>
            <label className="block capitalize text-xs mb-1">
              email address
            </label>

            <input
              className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
              type="email"
              placeholder="Enter email address"
              name="email"
              {...register('email', {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              })}
            />
            {errors.email && (
              <div className="text-red-400 text-xs mt-1">
                Email must be valid
              </div>
            )}
          </div>

          <div>
            <label className="block capitalize text-xs mb-1">
              phone number
            </label>

            <input
              className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
              type="tel"
              placeholder="Enter phone number"
              name="phone"
              {...register('phone', {
                required: true,
                pattern: /^[0-9]*$/,
              })}
            />
            {errors.phone && (
              <div className="text-red-400 text-xs mt-1">
                Phone must be valid
              </div>
            )}
          </div>

          <div className="col-span-2">
            <label className="block capitalize text-xs mb-1">password</label>
            <input
              name="password"
              className="w-full border border-dark-3 rounded text-base p-3 focus:outline-0 focus:border-dark-2"
              type="password"
              placeholder="Enter password"
              {...register('password', {
                required: true,
                minLength: 8,
              })}
            />
            {errors.password && (
              <div className="text-red-400 text-xs mt-1">
                Password must be more than 8 characters
              </div>
            )}
          </div>

          <div className="col-span-2">
            <label className="block capitalize text-xs mb-1">role</label>

            <SelectField
              options={option}
              label=""
              placeholder="Select role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
          </div>

          <div className="col-span-2">
            <button className="text-center w-full bg-primary-green border border-gray-2 capitalize text-white text-sm font-semibold py-3 rounded-md">
              {isSubmitting ? 'Sending...' : 'send invite'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTeam;
